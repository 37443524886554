import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { Location } from 'vue-router'

export interface NavigationState {
  pile: Array<Location>
  cancelSaveDialog: boolean
  cancelSaveNextRoute: Location | null
}

@Module({ name: 'navigation', namespaced: true })
class NavigationModule extends VuexModule implements NavigationState {
  public pile: Array<Location> = []
  public cancelSaveDialog = false
  public cancelSaveNextRoute: Location | null = null

  @Mutation
  public addRoute (route: Location): void {
    const lastRoute = this.pile[this.pile.length - 1]
    if (lastRoute?.path !== route.path) {
      this.pile.push(route)
    }
  }

  @Mutation
  public removeRoute (): void {
    this.pile.pop()
  }

  @Mutation
  public openCancelSaveDialog (to: Location): void {
    this.cancelSaveDialog = true
    this.cancelSaveNextRoute = to
  }

  @Mutation
  public closeCancelSaveDialog (): void {
    this.cancelSaveDialog = false
  }

  @Mutation
  public forceCancelSaveNextRoute (to: Location): void {
    this.cancelSaveNextRoute = to
  }

  @Mutation
  public resetCancelSaveNextRoute (): void {
    this.cancelSaveNextRoute = null
  }

  @Mutation
  public resetPile (): void {
    this.pile = []
  }

  get getLastSavedRoute (): Location | null {
    return this.pile[this.pile.length - 1]
  }

  get getReturnPreviousRoute (): Location | null {
    return this.pile[this.pile.length - 2]
  }

  get getLastRouteLogisticSession (): Location | null {
    let routeLogisticSession = null
    this.pile.forEach((route: Location) => {
      if (route.name === 'LogisticSession Edit') {
        routeLogisticSession = route
      }
    })

    return routeLogisticSession
  }

  get getLastRouteUserGroup (): Location | null {
    let routeUserGroup = null
    this.pile.forEach((route: Location) => {
      if (
        route.name === 'UserGroup Create' ||
        route.name === 'UserGroup Edit'
      ) {
        routeUserGroup = route
      }
    })

    return routeUserGroup
  }

  get getLastRouteNeedTraining (): Location | null {
    let routeNeedTraining = null
    this.pile.forEach((route: Location) => {
      if (
        route.name === 'NeedTraining Create' ||
        route.name === 'NeedTraining Edit'
      ) {
        routeNeedTraining = route
      }
    })

    return routeNeedTraining
  }
}

export default NavigationModule
