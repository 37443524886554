/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import http from '@/plugins/axios'
import { Profession } from '@/api/interfaces/profession'

export interface ProfessionFormState {
  profession: Profession
  isChanged: boolean
  isSubmitted: boolean
}

export function newProfession (): Profession {
  return {
    label: '',
    isActive: true,
  }
}

@Module({ name: 'professionForm', namespaced: true })
class ProfessionFormModule extends VuexModule implements ProfessionFormState {
  public profession: Profession = newProfession();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setProfession (profession: Profession): void {
    this.profession = profession
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean):void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getProfession (): Profession {
    return this.profession
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Profession> {
    const response = await http.get(`/api/professions/${id}`)
    const profession = response.data as Profession
    this.context.commit('setProfession', profession)
    return profession
  }

  @Action({ rawError: true })
  public async save (): Promise<Profession> {
    this.context.commit('setIsSubmitted', true)
    try {
      if (this.profession['@id']) {
        const response = await http.put(this.profession['@id'], this.profession)
        this.context.commit('setProfession', response.data)
      } else {
        const response = await http.post('/api/training_types', this.profession)
        this.context.commit('setProfession', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
     return this.profession
  }

  @Action({ rawError: true })
  public async reset (): Promise<Profession> {
    const profession: Profession = newProfession()
    this.context.commit('setProfession', profession)
    this.context.commit('setIsSubmitted', false)
    return profession
  }
}

export default ProfessionFormModule
