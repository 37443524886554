import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { MatriculationResponse } from '@/api/interfaces/matriculationResponse'

export interface MatriculationState {
  isLoading: boolean
}

@Module({ name: 'matriculation', namespaced: true })
class MatriculationModule extends VuexModule implements MatriculationState {
  public isLoading = false

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async loadCarInformations (matriculation: string): Promise<MatriculationResponse> {
    try {
      this.context.commit('setIsLoading', true)
      const response = await http.get(`api/plaque_immatriculation_informations/${matriculation}`)

      return response.data
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }
}

export default MatriculationModule
