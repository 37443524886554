/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarDealer } from '@/api/interfaces/cardealer'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface CarDealerListState {
  list: Array<CarDealer>
  totalItems: number
  loading: boolean
}

export function newCarDealer (): CarDealer {
  return {
      '@id': '',
      code: '',
      name: '',
      carDealerRegion: '',
  }
}

@Module({ name: 'carDealerList', namespaced: true })
class CarDealerListModule extends VuexModule implements CarDealerListState {
  public list: Array<CarDealer> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<CarDealer>): void {
    this.list = list
  }

  @Mutation
  public addCarDealer (item: CarDealer): void {
    this.list.push(item)
    this.totalItems++
  }

  @Mutation
  public replaceCarDealer (carDealer: CarDealer): void {
    const position = this.list.findIndex(element => element['@id'] === carDealer['@id'])
    if (this.list[position]) {
      this.list[position] = carDealer
    }
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public toggleLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<CarDealer>> {
    this.context.commit('toggleLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/car_dealers${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('toggleLoading')
    return response.data['hydra:member'] as Array<CarDealer>
  }

  @Action({ rawError: true })
  public async post (carDealer : CarDealer): Promise<CarDealer> {
    const response = await http.post('/api/car_dealers', carDealer)
    this.context.commit('addCarDealer', response.data)
    return response.data as CarDealer
  }

  @Action({ rawError: true })
  public async put (carDealer: CarDealer): Promise<CarDealer> {
    if (carDealer['@id']) {
      const response = await http.put(carDealer['@id'], carDealer)
      this.context.commit('replaceCarDealer', response.data)
      return response.data as CarDealer
    }
    return this.post(carDealer)
  }

  @Action({ rawError: true })
  public async loadByID (id: string): Promise<CarDealer> {
    const response = await http.get(`/api/car_dealers/${id}`)
    return response.data as CarDealer
  }
}

export default CarDealerListModule
