













































/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from '@vue/composition-api'
// Utilities
import { kebabCase } from 'lodash'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'ItemGroup',

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['barColor']),
    children (): any {
      return this.item.children.map((item: any) => ({
        ...item,
        to: !item?.to ? undefined : item.to,
      }))
    },
    computedText () {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach((val: any) => {
        text += val.substring(0, 1)
      })

      return text
    },
    group (): string {
      return this.genGroup(this.item.children)
    },
  },

  methods: {
    genGroup (children: []): string {
      return children
        .filter((item: any) => item?.to)
        .map((item: any) => {
          const parent = item.group || this.item.group
          let group = `${parent}/${kebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`
          }

          return group
        }).join('|')
    },
  },
})
