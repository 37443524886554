


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Subheading',

  props: {
    subheading: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
})
