import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { EmailNotificationTypeDocument } from '@/api/enums/emailNotificationTypeDocument'
import { EmailNotificationType } from '@/api/enums/emailNotificationType'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { EmailNotificationBrand } from '@/api/enums/emailNotificationBrand'

export interface NotificationEmailState {
  list: Array<NotificationEmail>
  loading: boolean,
  totalItems: number
}

@Module({ name: 'notificationEmail', namespaced: true })
class NotificationEmailModule extends VuexModule implements NotificationEmailState {
  public list: Array<NotificationEmail> = [];
  public loading = false;
  public totalItems = 0;

  @Mutation
  public setList (list: Array<NotificationEmail>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (totalItems: number): void {
    this.totalItems = totalItems
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<NotificationEmail>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/notification_emails${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<NotificationEmail>
  }

  @Action({ rawError: true })
  public async getNotificationEmailByNeedTraining (session: {
    needTraining: NeedTraining,
    emailNotificationTypeDocument: EmailNotificationTypeDocument,
    emailNotificationType: EmailNotificationType,
  }): Promise<NotificationEmail> {
    const needTraining = session.needTraining
    let brandVehicle = EmailNotificationBrand.GENERIC

    if (
      needTraining.trainingType &&
      (needTraining.trainingType as TrainingType)?.specificToyota
    ) {
      const brand = needTraining.carBrand?.brand?.toUpperCase()
      if (brand in EmailNotificationBrand) {
        brandVehicle = brand.toLowerCase()
      }
    }

    return await this.getNotificationEmail({
      emailNotificationTypeDocument: session.emailNotificationTypeDocument,
      emailNotificationType: session.emailNotificationType,
      emailNotificationBrand: brandVehicle,
      trainingTypeId: needTraining.trainingType?.id || null,
    })
  }

  @Action({ rawError: true })
  public async getNotificationEmailByTrainingSession (session: {
    trainingSession: TrainingSession,
    logisticSession: LogisticSession,
    emailNotificationTypeDocument: EmailNotificationTypeDocument,
    emailNotificationType: EmailNotificationType,
  }): Promise<NotificationEmail> {
    const trainingSession = session.trainingSession
    const logisticSession = session.logisticSession
    let brandVehicle = EmailNotificationBrand.GENERIC

    if (
      trainingSession.trainingType &&
      (trainingSession.trainingType as TrainingType)?.specificToyota
    ) {
      for (const vehicle of logisticSession.vehicles) {
        const brand = vehicle.carBrand?.brand?.toUpperCase()
        if (brand in EmailNotificationBrand) {
          brandVehicle = brand.toLowerCase()
          break
        }
      }
    }

    return await this.getNotificationEmail({
      emailNotificationTypeDocument: session.emailNotificationTypeDocument,
      emailNotificationType: session.emailNotificationType,
      emailNotificationBrand: brandVehicle,
      trainingTypeId: (trainingSession.trainingType as TrainingType)?.id || null,
    })
  }

  @Action({ rawError: true })
  public async deleteById (id: number): Promise<void> {
    this.context.commit('setLoading')
    try {
      await http.delete(`/api/notification_emails/${id}`)
      this.context.commit('setList', this.list.filter(item => item.id !== id))
    } finally {
      this.context.commit('setLoading')
    }
  }

  @Action({ rawError: true })
  private async getNotificationEmail (params: {
    emailNotificationTypeDocument: EmailNotificationTypeDocument,
    emailNotificationType: EmailNotificationType,
    emailNotificationBrand: EmailNotificationBrand,
    trainingTypeId: number | null,
  }): Promise<NotificationEmail> {
    const response = await http.get(`/api/notification_emails/${params.emailNotificationTypeDocument}/${params.emailNotificationType}/${params.emailNotificationBrand}/${params.trainingTypeId}`)
    return response.data as NotificationEmail
  }
}

export default NotificationEmailModule
