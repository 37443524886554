









































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'MaterialAlert',
  methods: {
    ...mapActions('user', {
      deleteMessage: 'deleteMessage',
    }),
  },
})
