






















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Pagination',
  data () {
    return {
      changePaginationPage: 1,
    }
  },
  props: {
    paginationOptions: {
      type: Object,
      required: true,
    },
    nbPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    computedPaginationOptions (): any {
      return Object.assign({}, this.paginationOptions)
    },
  },
  watch: {
    changePaginationPage: {
      handler () {
        if (
          this.changePaginationPage &&
          this.changePaginationPage >= 1 &&
          this.changePaginationPage <= this.nbPages
        ) {
          this.paginationOptions.page = this.changePaginationPage
        }
      },
    },
    computedPaginationOptions: {
      deep: true,
      handler: function (newPaginationOptions, oldPaginationOptions) {
        if (
          newPaginationOptions.itemsPerPage !==
          oldPaginationOptions.itemsPerPage
        ) {
          this.paginationOptions.page = 1
        }
        this.$emit('changePaginationOptions', this.paginationOptions)
      },
    },
  },
})
