import store from '@/store'
import router from '@/router'
import { groupSuperAdmin } from '@/config-constantes'

export function userConnectedHasRoles (routeRoles: string[]): boolean {
  const userRoles = store.getters['user/roles']
  if (!userRoles || !routeRoles) {
    return false
  }
  const roles = userRoles.filter((userRole: string) => routeRoles.includes(userRole))

  if (!roles) {
    return false
  }
  return roles.length !== 0
}

export function userConnectedHasRole (role: string): boolean {
  const userRoles = store.getters['user/roles']
  if (!userRoles) {
    return false
  }
  return userRoles.includes(role)
}

export function userConnectedCanAccess (routeName: string): boolean {
  const routeRoles = router.resolve({ name: routeName }).resolved.meta?.roles
  if (!routeRoles) {
    return false
  }

  return userConnectedHasRoles(routeRoles)
}

export function userConnectedHasGroup (group: string): boolean {
  const userGroups = store.getters['user/groups']
  if (!userGroups) {
    return false
  }
  return userGroups.includes(group)
}

export function userConnectedCanChangeRole (role: string): boolean {
  return userConnectedHasGroup(groupSuperAdmin) || userConnectedHasRole(role)
}

export function isReadonly (): boolean {
  const userRoles = store.getters['user/roles']
  const route = router.currentRoute
  if (!userRoles) {
    return false
  }
  const roles = userRoles.filter((userRole: string) => route.meta.roles.includes(userRole))
  if (!roles) {
    return false
  }
  for (const role of roles) {
    if (role.indexOf('_WRITE_') !== -1) {
      return false
    }
  }

  return true
}
