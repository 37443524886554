/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface TrainingSessionListState {
  list: Array<TrainingSession>
  totalItems: number
  loading: boolean
}

@Module({ name: 'TrainingSessionList', namespaced: true })
class TrainingSessionListModule extends VuexModule
  implements TrainingSessionListState {
  public list: Array<TrainingSession> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<TrainingSession>): void {
    this.list = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<TrainingSession>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/training_sessions${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<TrainingSession>
  }

  @Action({ rawError: true })
  public reset (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
  }
}

export default TrainingSessionListModule
