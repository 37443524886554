/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TraineeImport } from '@/api/interfaces/traineeimport'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { TraineeImportType } from '@/api/interfaces/traineeimporttype'
import { TraineeImportTypeEnum } from '@/api/enums/traineeImportType'

export interface importState {
  currentImport: TraineeImport|null
  list: Array<TraineeImport>
  totalItems: number
}

@Module({ name: 'import', namespaced: true })
class importModule extends VuexModule implements importState {
  public currentImport: TraineeImport|null = null
  public list: Array<TraineeImport> = []
  public totalItems = 0

  @Mutation
  public setCurrentImport (currentImport: TraineeImport): void {
    this.currentImport = currentImport
  }

  @Mutation
  public setList (list: Array<TraineeImport>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async upload (params: unknown): Promise<TraineeImport> {
    const response = await http.post('/api/trainee/import/upload', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    this.context.commit('setCurrentImport', response.data)
    // this.context.dispatch('uploadAdvancement', response.data.id)
    return response.data as TraineeImport
  }

  @Action({ rawError: true })
  public async loadList (queryParams: QueryParams): Promise<Array<TraineeImport>> {
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/trainee_imports${option}`)
    const list: Array<TraineeImport> = response.data['hydra:member']
    if (this.currentImport !== null) {
      const currentImport: TraineeImport|undefined = list.find((traineeImport: TraineeImport) => traineeImport.id === this.currentImport?.id)
      if (currentImport) {
        this.context.commit('setCurrentImport', currentImport)
      }
    }
    this.context.commit('setList', list)
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])

    return list
  }

  @Action({ rawError: true })
  public async load (id: number): Promise<TraineeImport> {
    const response = await http.get(`/api/trainee_imports/${id}`)
    const traineeImport = response.data as TraineeImport
    this.context.commit('setCurrentImport', traineeImport)
    return traineeImport
  }

  @Action({ rawError: true })
  public async loadTraineeImportTypeByName (traineeImportType: TraineeImportTypeEnum): Promise<TraineeImportType> {
    const response = await http.get(`/api/trainee_import_types?name=${traineeImportType}`)
    return response.data['hydra:member'][0] as TraineeImportType
  }
}

export default importModule
