/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TrainingLocation } from '@/api/interfaces/traininglocation'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface TrainingLocationListState {
  list: Array<TrainingLocation>
  totalItems: number
  loading: boolean
}

@Module({ name: 'trainingLocationList', namespaced: true })
class TrainingLocationListModule extends VuexModule implements TrainingLocationListState {
  public list: Array<TrainingLocation> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<TrainingLocation>): void {
    this.list = list
  }

  @Mutation
  public toggleLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public addToList (item: TrainingLocation): void {
    this.list.push(item)
    this.totalItems++
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<TrainingLocation> {
    const response = await http.get(`/api/training_locations/${id}`)
    const trainingLocation = response.data as TrainingLocation
    this.context.commit('addToList', trainingLocation)
    return trainingLocation
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<TrainingLocation>> {
    this.context.commit('toggleLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/training_locations${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('toggleLoading')
    return response.data['hydra:member'] as Array<TrainingLocation>
  }

  @Action({ rawError: true })
  public async addTrainingLocation (trainingLocation: TrainingLocation) {
    this.context.commit('addToList', trainingLocation)
  }

  @Action({ rawError: true })
  public async deleteById (id: number): Promise<Array<TrainingLocation>> {
    this.context.commit('toggleLoading')
    try {
      const response = await http.delete(`/api/training_locations/${id}`)
      this.context.commit('setList', this.list.filter(item => item.id !== id))
      this.context.commit('setTotalItems', this.totalItems - 1)
      return response.data['hydra:member'] as Array<TrainingLocation>
    } finally {
      this.context.commit('toggleLoading')
    }
  }
}

export default TrainingLocationListModule
