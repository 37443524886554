/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Trainer } from '@/api/interfaces/trainer'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface TrainerListState {
  list: Array<Trainer>
  totalItems: number
  loading: boolean
}

@Module({ name: 'trainerList', namespaced: true })
class TrainerListModule extends VuexModule implements TrainerListState {
  public list: Array<Trainer> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<Trainer>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Trainer>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/trainers${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Trainer>
  }
}

export default TrainerListModule
