export enum StatusLogisticSessionSupplierRelationship {
  SEMINAR_ROOM = 'seminar-room',
  CATERER = 'caterer',
  HOUSING = 'housing',
}

export interface ItemStatusLogisticSessionSupplierRelationship {
  text: string,
  value: string,
}
