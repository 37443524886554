import { format } from 'date-fns'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { Vehicle } from '@/api/interfaces/vehicle'
import { Trainer } from '@/api/interfaces/trainer'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { trainingSessionStatuses } from '@/config-constantes'

interface RowsDTO {
  label: string | undefined;
  icon: string;
  iconTitle?: string;
}

export class LogisticSessionDTO {
  readonly logisticSession: LogisticSession;

  i: number;

  id: number;
  header: Array<RowsDTO>;
  headerDetails: Array<RowsDTO>;
  sessions: Array<Array<RowsDTO>>
  sessionsDetails: Array<Array<RowsDTO>>
  commentary: string | undefined;
  commentaryMaterials: string | undefined;
  positionned: boolean;
  isDisabled: boolean;
  isDisplayed: boolean;

  x: number | undefined;
  y: number | undefined;
  h: number;
  w: number;

  constructor (logisticSession: LogisticSession) {
    this.logisticSession = logisticSession

    this.id = this.logisticSession.id as number
    this.header = []
    this.headerDetails = []
    this.sessions = []
    this.sessionsDetails = []
    this.commentary = this.logisticSession.commentary
    this.commentaryMaterials = this.logisticSession.commentaryMaterials
    this.positionned = false
    const logisticSessionTrainingLocation = this.logisticSession
      .trainingLocation.name
    const isPreparedSession = this.logisticSession.technicalPreparation
    const startAt = format(new Date(this.logisticSession.startAt as string), 'HH:mm')
    const endAt = format(new Date(this.logisticSession.endAt as string), 'HH:mm')
    const trainingDate = format(new Date(this.logisticSession.startAt as string), 'dd/MM/yyyy')
    const concatenatedHours = `${startAt}-${endAt}`
    const trainingSessionLocation: Array<string> = Array.from(new Set(
      this.logisticSession.trainingSessions.map(
        (trainingSession: TrainingSession) => {
          return trainingSession?.trainingLocation?.name
        },
      )),
    )

      const sameTrainingLocationsInTrainingSessions =
      trainingSessionLocation.length === 1

      const sameTrainingTypes =
      this.getTrainingSessionTypes().length === 1
      const manyTrainingSessions = this.logisticSession.trainingSessions.length > 1

    // adding data in header
    if (this.getTrainingSessionTypes().length) {
      this.header.push({
        icon: isPreparedSession ? '' : 'mdi-alert-outline',
        label: this.getTrainingSessionTypes().join(' / '),
      })
    }

    if (startAt && endAt) {
      this.header.push({
        icon: 'mdi-clock-outline',
        label: concatenatedHours,
      })
    }

    if (logisticSessionTrainingLocation) {
      this.header.push({
        icon: 'mdi-map-marker-outline',
        label: logisticSessionTrainingLocation,
      })
    }

    if (this.getTrainers().length) {
      this.header.push({
        icon: 'mdi-wallet-travel',
        label: this.getTrainers().join(', '),
      })
    }

    if (this.getVehicles().length) {
      this.header.push({
        icon: 'mdi-car-outline',
        label: this.getVehicles().join(' / '),
      })
    }

    // adding data in headerDetails
    if (this.getTrainingSessionTypes().length) {
      this.headerDetails.push({
        icon: isPreparedSession ? '' : 'mdi-alert-outline',
        label: this.getTrainingSessionTypes().join(' / '),
      })
    }

    if (startAt && endAt) {
      this.headerDetails.push({
        icon: 'mdi-clock-outline',
        label: concatenatedHours,
      })
    }

    if (trainingDate) {
      this.headerDetails.push({
        icon: 'mdi-calendar-outline',
        label: trainingDate,
      })
    }

    if (logisticSessionTrainingLocation) {
      this.headerDetails.push({
        icon: 'mdi-map-marker-outline',
        label: logisticSessionTrainingLocation,
      })
    }

    if (this.getTrainers().length) {
      this.headerDetails.push({
        icon: 'mdi-wallet-travel',
        label: this.getTrainers().join(', '),
      })
    }

    if (this.getVehicles().length) {
      this.headerDetails.push({
        icon: 'mdi-car-outline',
        label: this.getVehicles().join(' / '),
      })
    }

    const nameHousing = this.logisticSession.nameHousing
    const addressHousing = this.logisticSession.addressHousing
    const cityHousing = this.logisticSession.cityHousing
    const zipCodeHousing = this.logisticSession.zipCodeHousing
    const priceHousing = this.logisticSession.priceHousing ? ` - ${this.logisticSession.priceHousing}€` : ''

    const concatenatedHousingInfos = `${nameHousing} ${addressHousing} ${cityHousing} ${zipCodeHousing}${priceHousing}`

    if (concatenatedHousingInfos.trim().length) {
      this.headerDetails.push({
        icon: 'mdi-bed-outline',
        label: concatenatedHousingInfos,
      })
    }

    // adding data in sessions
    this.logisticSession.trainingSessions.forEach(
      (trainingSession: TrainingSession, index: number) => {
        const rows = [] as RowsDTO[]

        rows.push({
          icon: this.getStatusIcon(trainingSession.status as string),
          label: sameTrainingTypes ? `Session stagiaire #${index + 1}` : (trainingSession.trainingType as TrainingType).name as string,
          iconTitle: `training_session.form.status.${trainingSession.status}`,
        })

        if (!sameTrainingLocationsInTrainingSessions && trainingSession.trainingLocation.name) {
          rows.push({
            icon: 'mdi-map-marker-outline',
            label: trainingSession.trainingLocation.name,
          })
        }

        const startAt = format(new Date(trainingSession.startAt as string), 'HH:mm')
        const endAt = format(new Date(trainingSession.endAt as string), 'HH:mm')
        const concatenatedHours = `${startAt}-${endAt}`
        if (startAt && endAt && manyTrainingSessions) {
          rows.push({
            icon: 'mdi-clock-outline',
            label: concatenatedHours,
          })
        }

        const numberTraineesRegistered = trainingSession.numberTraineesRegistered === 0 || trainingSession.numberTraineesRegistered === null ? 0 : trainingSession.numberTraineesRegistered
        const goalNumberTrainees = trainingSession.goalNumberTrainees === 0 || trainingSession.goalNumberTrainees === null ? 0 : trainingSession.goalNumberTrainees
        rows.push({
          icon: 'mdi-account-multiple-outline',
          label: `${numberTraineesRegistered}/${goalNumberTrainees}`,
        })

        const sessionCompanies = Array.from(new Set(trainingSession.needTrainings.map(el => el.company?.name)))
        if (sessionCompanies.length) {
          rows.push({
              icon: 'mdi-domain',
              label: sessionCompanies.filter(element => element !== undefined).join(', '),
            })
        }

        this.sessions.push(rows)
      },
      )

    // adding data in sessionsDetails
    this.logisticSession.trainingSessions.forEach(
      (trainingSession: TrainingSession) => {
        const sessionsDetails = [] as RowsDTO[]

        if (sameTrainingTypes) {
          sessionsDetails.push({
            icon: '',
            label: (trainingSession.trainingType as TrainingType).name as string,
          })
        }

        if (trainingSession.status) {
          sessionsDetails.push({
            icon: this.getStatusIcon(trainingSession.status),
            label: `training_session.form.status.${trainingSession.status}`,
            iconTitle: `training_session.form.status.${trainingSession.status}`,
          })
        }

        if (!sameTrainingLocationsInTrainingSessions && trainingSession.trainingLocation.name) {
          sessionsDetails.push({
            icon: 'mdi-map-marker-outline',
            label: trainingSession.trainingLocation.name,
          })
        }

        const startAt = format(new Date(trainingSession.startAt as string), 'HH:mm')
        const endAt = format(new Date(trainingSession.endAt as string), 'HH:mm')
        const concatenatedHours = `${startAt}-${endAt}`

        if (startAt && endAt) {
          sessionsDetails.push({
            icon: 'mdi-clock-outline',
            label: concatenatedHours,
          })
        }

        const numberTraineesRegistered = trainingSession.numberTraineesRegistered === 0 || trainingSession.numberTraineesRegistered === null ? 0 : trainingSession.numberTraineesRegistered
        const goalNumberTrainees = trainingSession.goalNumberTrainees === 0 || trainingSession.goalNumberTrainees === null ? 0 : trainingSession.goalNumberTrainees
        sessionsDetails.push({
          icon: 'mdi-account-multiple-outline',
          label: `${numberTraineesRegistered}/${goalNumberTrainees}`,
        })

        const sessionCompanies = Array.from(new Set(trainingSession.needTrainings.map(el => el.company?.name)))
        if (sessionCompanies.length) {
        sessionsDetails.push({
            icon: 'mdi-domain',
            label: sessionCompanies.filter(element => element !== undefined).join(', '),
          })
        }

        if (this.hasCommentary) {
          sessionsDetails.push({
            icon: 'mdi-forum-outline',
            label: trainingSession.commentary,
          })
        }
        this.sessionsDetails.push(sessionsDetails)
      },
      )

      this.isDisabled = false
      this.isDisplayed = true
      this.i = this.logisticSession.id as number
      this.w = 1 // session 2 days later
      this.h = this.countRows() + 2 // session 2 days later
  }

  private hasDuplicates (array: Array<string>): boolean {
    return new Set(array).size !== array.length
  }

  private getTrainingSessionTypes (): Array<string | void> {
    return Array.from(
      new Set(
        this.logisticSession.trainingSessions.map(
          (trainingSession: TrainingSession) =>
            (trainingSession.trainingType as TrainingType)?.name,
        ),
      ),
    )
  }

  private getVehicles (): Array<string> {
    return Array.from(
      new Set(
        this.logisticSession.vehicles.map((vehicle: Vehicle) => {
          const carBrand = vehicle?.carBrand?.brand
          const carModel = vehicle?.carModel?.model ? ' - ' + vehicle?.carModel?.model : ''
          return carBrand + carModel
        }),
      ),
    )
  }

  private getTrainers (): Array<string> {
    return Array.from(
      new Set(
        this.logisticSession.trainers.map((trainer: Trainer) => {
          return `${trainer.firstName} ${trainer.lastName}`
        }),
      ),
    )
  }

  private getStatusIcon (status : string) : string {
    const trainindSessionsStatus = trainingSessionStatuses.find((trainingSessionStatus : any) => trainingSessionStatus.value === status)
    return trainindSessionsStatus ? trainindSessionsStatus.icon : ''
  }

  private countSessions (): number {
    let rows = 0
    this.sessions.forEach((session) => { session.forEach(() => rows++) })
    return rows
  }

  private countRows (): number {
    return this.header.length + this.countSessions()
  }

  public set setPositionX (x: number) {
    this.x = x
  }

  public set setPositionY (y: number) {
    this.y = y
    this.positionned = true
  }

  public set setPositionW (w: number) {
    this.w = w
  }

  public get color (): string {
    const sessionsColors = (this.logisticSession.trainingSessions.find((trainingSession : TrainingSession) => typeof (trainingSession.trainingType) !== 'string' && trainingSession.trainingType?.color)?.trainingType as TrainingType).color
    return sessionsColors || '#EC6958'
  }

  public get hasCommentary (): boolean {
    return this.logisticSession.commentary !== null || this.logisticSession.commentaryMaterials !== null || this.logisticSession.trainingSessions.map((trainingSession: TrainingSession) => trainingSession.commentary).length !== 0
  }
}
