/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from '@/plugins/axios'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UserModule from '@/store/modules/user'
import store from '@/store'
import { IFiltersTrainingOfficerView } from '@/api/interfaces/filtersTrainingOfficerView'

interface FiltersTrainingOfficerViewState {
  isSubmittingPost: boolean,
}

@Module({ name: 'filtersTrainingOfficerView', namespaced: true })
class FiltersTrainingOfficerViewModule extends VuexModule implements FiltersTrainingOfficerViewState {
  public isSubmittingPost = false

  @Mutation
  public setIsSubmittingPost (isSubmittingPost: boolean): void {
    this.isSubmittingPost = isSubmittingPost
  }

  @Action({ rawError: true })
  public async save (filters: IFiltersTrainingOfficerView): Promise<void> {
    if (this.isSubmittingPost) {
      // Case method save is call multiple times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected

    if (userConnected.userApplication.filtersTrainingOfficerView === null) {
      this.context.commit('setIsSubmittingPost', true)
      const filtersTrainingOfficerViewResponse = await http.post('api/filters_training_officer_views', {
        filters: filters,
      })
      userConnected.userApplication.filtersTrainingOfficerView = filtersTrainingOfficerViewResponse.data
      store.commit('user/setUserConnected', userConnected)
      this.context.commit('setIsSubmittingPost', false)
    } else {
      if (JSON.stringify(userConnected.userApplication.filtersTrainingOfficerView.filters) !== JSON.stringify(filters)) {
        await http.put('api/filters_training_officer_views/' + userConnected.userApplication.filtersTrainingOfficerView.id, {
          filters: filters,
        })
        userConnected.userApplication.filtersTrainingOfficerView.filters = filters
        store.commit('user/setUserConnected', userConnected)
      }
    }
  }
}
export default FiltersTrainingOfficerViewModule
