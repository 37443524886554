/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from '@/plugins/axios'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UserModule from '@/store/modules/user'
import Query from '@/views/dashboard/planning/types/Query'
import store from '@/store'
import { IFilters } from '@/api/interfaces/filtersPlanning'

interface FiltersPlanningState {
  filters: any,
  query: Query | null,
  isSubmittingPost: boolean,
}

@Module({ name: 'filtersPlanning', namespaced: true })
class FiltersPlanningModule extends VuexModule implements FiltersPlanningState {
  public filters: any = {};
  public query: Query = new Query();
  public isSubmittingPost = false

  @Mutation
  public setFilters (filters: any): void {
    this.filters = filters
  }

  @Mutation
  public setQuery (query: Query): void {
    this.query = query
  }

  @Mutation
  public setIsSubmittingPost (isSubmittingPost: boolean): void {
    this.isSubmittingPost = isSubmittingPost
  }

  @Action({ rawError: true })
  public async save (): Promise<string | void> {
    if (this.isSubmittingPost) {
      // Case method save is call three times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected
    const data: IFilters = {
      area: this.filters.area,
      company: this.filters.company,
      displayedCancel: this.filters.displayCanceled,
      status: this.filters.status,
      technicalPreparation: this.filters.technicalPreparation,
      trainer: this.filters.trainer,
      trainingLocation: this.filters.trainingLocation,
      trainingType: this.filters.trainingType,
      currentDay: this.query.currentDay,
      logisticArea: this.query.logisticArea,
    }

    if (userConnected.userApplication.filtersPlanning === null) {
      this.context.commit('setIsSubmittingPost', true)
      const filtersPlanningResponse = await http.post('api/filters_plannings', {
        filters: data,
      })
      userConnected.userApplication.filtersPlanning = filtersPlanningResponse.data
      store.commit('user/setUserConnected', userConnected)
      this.context.commit('setIsSubmittingPost', false)
    } else {
      if (JSON.stringify(userConnected.userApplication.filtersPlanning.filters) !== JSON.stringify(data)) {
        await http.put('api/filters_plannings/' + userConnected.userApplication.filtersPlanning.id, {
          filters: data,
        })
        userConnected.userApplication.filtersPlanning.filters = data
        store.commit('user/setUserConnected', userConnected)
      }
    }
    return 'done'
  }
}
export default FiltersPlanningModule
