






































































import { defineComponent } from '@vue/composition-api'
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'

export default defineComponent({
  name: 'BaseMaterialWizard',

  mixins: [Proxyable],

  props: {
    availableSteps: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
})
