


















































import { defineComponent } from '@vue/composition-api'
import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'

export default defineComponent({
  name: 'ListSelectUserFilter',
  components: { SelectTrainingOfficer },
  data () {
    return {
      filterValue: '',
    }
  },
  props: {
    translation: {
      type: String,
    },
    value: {
      type: [String, Array],
    },
    items: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    (this.filterValue as unknown) = this.value
  },
  methods: {
    filter () {
      const value = this.filterValue
      this.$emit('filter-list', value)
    },
    clear () {
      this.filterValue = ''
      this.$emit('filter-list', '')
    },
  },
})
