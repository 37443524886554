


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ScrollTop',

  data () {
    return {
      scrollTop: false,
    }
  },

  methods: {
    onScroll (e: any) {
      if (typeof window === 'undefined') return
      if (e === null) return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollTop = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
  },
})
