



















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Initials',
  props: {
    user: {
      type: Object,
      required: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 30,
    },
  },
})
