/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { LogisticSession, TrainingOfficerCompany } from '@/api/interfaces/logisticSession'
import { TrainingLocation } from '@/api/interfaces/traininglocation'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { Entity } from '@/api/interfaces/entity'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { formatISO } from 'date-fns'
import { Vehicle } from '@/api/interfaces/vehicle'
import { TeachingTool } from '@/api/interfaces/teachingtool'
import { NeedTraining } from '@/api/interfaces/needtraining'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'

export interface LogisticSessionFormState {
  logisticSession: LogisticSession,
  currentAssociationWithIdTrainingSession: number | null,
  isChanged: boolean,
  isSubmitted: boolean,
}

export interface FormCreateSession extends Entity {
  startAt?: string,
  endAt?: string,
  trainingLocation?: TrainingLocation,
  trainingType?: TrainingType,
  numberSessionToCreate: number,
  goalNumberTrainees: number,
}

export function newSession (): FormCreateSession {
  return {
    startAt: undefined,
    endAt: undefined,
    trainingLocation: undefined,
    trainingType: undefined,
    numberSessionToCreate: 1,
    goalNumberTrainees: 0,
  }
}

export function newLogisticSession (): LogisticSession {
  return {
    startAt: undefined,
    endAt: undefined,
    technicalPreparation: false,
    trainers: [],
    commentary: '',
    trainingSessions: [],
    trainingLocation: undefined,
    nameHousing: '',
    addressHousing: '',
    cityHousing: '',
    zipCodeHousing: '',
    priceHousing: undefined,
    commentaryHousing: '',
    infoHousing: '',
    vehicles: [],
    commentaryVehicle: '',
    teachingTools: [],
    commentaryMaterials: '',
    caterer: '',
    logisticSessionsDay: [],
    trainingOfficer: undefined,
    supplierRelationshipOfficer: undefined,
    secondaryTrainingOfficers: [],
  }
}

export function newTrainingSession (): TrainingSession {
  return {
    '@id': '',
    trainingType: undefined,
    trainingLocation: undefined,
    startAt: formatISO(new Date()),
    endAt: formatISO(new Date()),
    needTrainings: [],
    teachingAids: [],
    trainingPlanningSharepointLink: '',
  }
}

@Module({ name: 'logisticSessionForm', namespaced: true })
class LogisticSessionFormModule extends VuexModule implements LogisticSessionFormState {
  public logisticSession: LogisticSession = newLogisticSession()
  public currentAssociationWithIdTrainingSession: number | null = null
  public isChanged = false
  public isSubmitted = false

  @Mutation
  public setLogisticSession (logisticSession: LogisticSession): void {
    this.logisticSession = logisticSession
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation setCurrentAssociationWithIdTrainingSession (id: number | null): void {
    this.currentAssociationWithIdTrainingSession = id
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  @Mutation
  addNewTrainingSession () {
    const trainingSession = newTrainingSession()
    if (this.logisticSession.trainingSessions.length > 0) {
      trainingSession.trainingType = this.logisticSession.trainingSessions[0].trainingType
    }

    if (this.logisticSession.trainingLocation) {
      trainingSession.trainingLocation = this.logisticSession.trainingLocation
    }

    if ((trainingSession.trainingType as TrainingType).specificToyota) {
      trainingSession.status = TrainingSessionStatus.STATUS_OPTIONAL
    } else {
      trainingSession.status = TrainingSessionStatus.STATUS_IN_PROGRESS
    }

    trainingSession.teachingAids = (trainingSession.trainingType as TrainingType).teachingAids
    trainingSession.trainingPlanningSharepointLink = (trainingSession.trainingType as TrainingType).trainingPlanningSharepointLink

    this.logisticSession.trainingSessions.push(trainingSession)
  }

  @Mutation
  public setVehicles (vehicles: Vehicle[]): void {
    this.isChanged = true
    this.logisticSession.vehicles = vehicles
  }

  @Mutation
  public addVehicle (vehicle: Vehicle): void {
    if (vehicle['@id']) {
      if (!this.logisticSession.vehicles.find(v => v['@id'] === vehicle['@id'])) {
        this.isChanged = true
        this.logisticSession.vehicles.push(vehicle)
      }
    }
  }

  @Mutation
  public setTeachingTools (teachingTools: TeachingTool[]): void {
    this.isChanged = true
    this.logisticSession.teachingTools = teachingTools
  }

  @Mutation
  public addTeachingTool (teachingTool: TeachingTool): void {
    if (teachingTool['@id']) {
      if (!this.logisticSession.teachingTools.find(t => t['@id'] === teachingTool['@id'])) {
        this.isChanged = true
        this.logisticSession.teachingTools.push(teachingTool)
      }
    }
  }

  @Mutation
  public setNeedTrainingsForTrainingSession (data: { idTrainingSession: number, needTrainings: NeedTraining[] }): void {
    const trainingSession = this.logisticSession.trainingSessions.find((t: TrainingSession) => t.id === data.idTrainingSession)
    if (trainingSession) {
      this.isChanged = true
      trainingSession.needTrainings = data.needTrainings
    }
  }

  @Mutation
  public addNeedTrainingToTrainingSession (data: { idTrainingSession: number, needTraining: NeedTraining }): void {
    if (data.needTraining['@id']) {
      const statusesCodes = Vue.prototype.$appConfig.needTrainingStatusesCodesToPlanifie
      const needTrainingStatusCode = data.needTraining.status.code

      if (!statusesCodes.includes(needTrainingStatusCode)) {
        return
      }

      const trainingSession = this.logisticSession.trainingSessions.find((t: TrainingSession) => t.id === data.idTrainingSession)
      if (trainingSession && !trainingSession.needTrainings.find(n => n['@id'] === data.needTraining['@id'])) {
        this.isChanged = true
        trainingSession.needTrainings.push(data.needTraining)
      }
    }
  }

  get getLogisticSession (): LogisticSession {
    return this.logisticSession
  }

  get getCurrentAssociationWithIdTrainingSession (): number | null {
    return this.currentAssociationWithIdTrainingSession
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<LogisticSession> {
    const response = await http.get(`/api/logistic_sessions/${id}`)
    const logisticSession = { ...(response.data as LogisticSession) }
    this.context.commit('setLogisticSession', logisticSession)
    return this.logisticSession
  }

  @Action({ rawError: true })
  public async save (): Promise<LogisticSession> {
    this.context.commit('setIsSubmitted', true)
    try {
      const trainingSessions: Array<TrainingSession> = []
      const secondaryTrainingOfficers: Array<TrainingOfficerCompany> = []
      this.logisticSession.trainingSessions.forEach((trainingSession: TrainingSession): void => {
        trainingSessions.push(cloneObjectReplaceSubObjectByIRI(trainingSession))
      });
      (this.logisticSession.secondaryTrainingOfficers as Array<TrainingOfficerCompany>).forEach((trainingOfficerCompany: TrainingOfficerCompany): void => {
        secondaryTrainingOfficers.push(cloneObjectReplaceSubObjectByIRI(trainingOfficerCompany))
      })
      const logisticSession: LogisticSession = cloneObjectReplaceSubObjectByIRI(this.logisticSession)
      logisticSession.trainingSessions = trainingSessions
      logisticSession.secondaryTrainingOfficers = secondaryTrainingOfficers
      if (this.logisticSession['@id']) {
        const logisticSessionUpdated = await http.put(this.logisticSession['@id'], logisticSession)
        this.context.commit('setLogisticSession', logisticSessionUpdated.data)
      } else {
        const newLogisticSession = await http.post('/api/logistic_sessions', logisticSession)
        this.context.commit('setLogisticSession', newLogisticSession.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.logisticSession
  }

  @Action({ rawError: true })
  public async reset (): Promise<LogisticSession> {
    const logisticSession: LogisticSession = newLogisticSession()
    this.context.commit('setLogisticSession', logisticSession)
    this.context.commit('setIsSubmitted', false)
    this.context.commit('setIsChanged', false)
    this.context.commit('setCurrentAssociationWithIdTrainingSession', null)
    return logisticSession
  }

  @Action({ rawError: true })
  public async createSession (form: FormCreateSession): Promise<LogisticSession> {
    const session: FormCreateSession = cloneObjectReplaceSubObjectByIRI(form)
    const logisticSession: LogisticSession = newLogisticSession()
    let trainingSession: TrainingSession = newTrainingSession()

    logisticSession.startAt = session.startAt
    logisticSession.endAt = session.endAt
    logisticSession.trainingLocation = session.trainingLocation

    trainingSession.startAt = session.startAt
    trainingSession.endAt = session.endAt
    trainingSession.trainingLocation = session.trainingLocation
    trainingSession.trainingType = session.trainingType
    trainingSession.goalNumberTrainees = session.goalNumberTrainees
    trainingSession.teachingAids = form.trainingType?.teachingAids
    trainingSession.trainingPlanningSharepointLink = form.trainingType?.trainingPlanningSharepointLink

    if (form.trainingType?.specificToyota) {
      trainingSession.status = TrainingSessionStatus.STATUS_OPTIONAL
    } else {
      trainingSession.status = TrainingSessionStatus.STATUS_IN_PROGRESS
    }

    const trainingSessionCreated: AxiosResponse<TrainingSession> = await http.post('/api/training_sessions', trainingSession)
    trainingSession = trainingSessionCreated.data

    logisticSession.trainingSessions.push(trainingSession)

    let firstLogisticSessionCreated: LogisticSession | null = null

    if (form.numberSessionToCreate === 1) {
      this.context.commit('setLogisticSession', logisticSession)
      firstLogisticSessionCreated = await this.save()
    } else {
      for (let i = 1; i <= form.numberSessionToCreate; i++) {
        const newStartAt = new Date(session.startAt as string)
        const newEndAt = new Date(session.endAt as string)
        newStartAt.setDate(newStartAt.getDate() + i - 1)
        newEndAt.setDate(newEndAt.getDate() + i - 1)
        logisticSession.startAt = formatISO(newStartAt)
        logisticSession.endAt = formatISO(newEndAt)
        this.context.commit('setLogisticSession', logisticSession)
        const logisticSessionCreated = await this.save()

        if (i === 1) {
          firstLogisticSessionCreated = logisticSessionCreated
        }
      }
    }

    return firstLogisticSessionCreated as LogisticSession
  }

  @Action({ rawError: true })
  public async dissociate (idTrainingSession: number): Promise<void> {
    await http.post(
      `/api/logistic_sessions/${this.logisticSession.id}/training_session/${idTrainingSession}/dissociate`,
      {},
    )

    // remove training session from logistic session
    this.logisticSession.trainingSessions = this.logisticSession.trainingSessions.filter((trainingSession: TrainingSession) => {
      return trainingSession.id !== idTrainingSession
    })
  }
}

export default LogisticSessionFormModule
