export enum StatusLogisticSession {
  OPTION = 'option',
  DATE_VALIDATED = 'validated',
  DATE_OK = 'ok',
  DATE_RETURN_CLIENT = 'return-client',
}

export interface ItemStatusLogisticSession {
  text: string,
  value: string,
  class: string,
}

export const itemsStatusLogisticSession: Array<ItemStatusLogisticSession> = [
  {
    text: 'En option',
    value: StatusLogisticSession.OPTION,
    class: 'select-option',
  },
  {
    text: 'Date validée',
    value: StatusLogisticSession.DATE_VALIDATED,
    class: 'select-validate',
  },
  {
    text: 'Dossier OK',
    value: StatusLogisticSession.DATE_OK,
    class: 'select-ok',
  },
  {
    text: 'Dossier retour OK',
    value: StatusLogisticSession.DATE_RETURN_CLIENT,
    class: 'select-return',
  },
]
