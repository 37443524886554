/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { TrainingSession } from '@/api/interfaces/trainingsession'

export interface TrainerSpaceState {
  trainingSessions: Array<TrainingSession>
  totalItems: number
  loading: boolean
}

@Module({ name: 'trainerSpace', namespaced: true })
class TrainerSpaceModule extends VuexModule implements TrainerSpaceState {
  public trainingSessions: Array<TrainingSession> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setTrainingSessions (trainingSessions: Array<TrainingSession>): void {
    this.trainingSessions = trainingSessions
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (isLoading: boolean): void {
    this.loading = isLoading
  }

  @Action({ rawError: true })
  public async loadTrainingSessions (): Promise<Array<TrainingSession>> {
    this.context.commit('setLoading', true)
    const response = await http.get('/api/training_sessions/trainer_space')
    this.context.commit('setTrainingSessions', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading', false)
    return response.data['hydra:member'] as Array<TrainingSession>
  }
}

export default TrainerSpaceModule
