import { VuexModule, Module, Mutation } from 'vuex-module-decorators'

export interface ThemeState {
  barColor: string,
  barImage: string,
  drawer: boolean
}

@Module({ name: 'theme', namespaced: true })
class ThemeModule extends VuexModule implements ThemeState {
  public barColor = 'rgba(255, 230, 0, .8), rgba(255, 230, 0, .8)';
  public barImage = 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg';
  public drawer = false;

  @Mutation
  public setBarColor (barColor: string): void {
    this.barColor = barColor
  }

  @Mutation
  public setBarImage (barImage: string): void {
    this.barImage = barImage
  }

  @Mutation
  public setDrawer (drawer: boolean): void {
    this.drawer = drawer
  }
}

export default ThemeModule
