/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { CarModel } from '@/api/interfaces/carmodel'

export interface CarModelListState {
  list: Array<CarModel>
  totalItems: number
  loading: boolean
}

@Module({ name: 'carModelList', namespaced: true })
class CarModelListModule extends VuexModule implements CarModelListState {
  public list: Array<CarModel> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<CarModel>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (isLoading: boolean): void {
    this.loading = isLoading
  }

  @Action({ rawError: true })
  public async load (
    queryParams: QueryParams,
  ): Promise<Array<TrainingType>> {
    this.context.commit('setLoading', true)
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/car_models${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading', false)
    return response.data['hydra:member'] as Array<TrainingType>
  }
}

export default CarModelListModule
