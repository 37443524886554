






































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MaterialCard',

  props: {
    avatar: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'success',
    },
    hoverReveal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes (): any {
      return {
        'v-card--material--has-heading': this.hasHeading,
        'v-card--material--hover-reveal': this.hoverReveal,
      }
    },
    hasHeading (): boolean {
      return Boolean(this.$slots.heading || this.title || this.icon)
    },
    hasAltHeading (): boolean {
      return Boolean(this.$slots.heading || (this.title && this.icon))
    },
  },
})
