/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { getField, updateField } from 'vuex-map-fields'
import { CarModel } from '@/api/interfaces/carmodel'
import { CarEngine } from '@/api/interfaces/carengine'
import { CarFinish } from '@/api/interfaces/carfinish'

function newCarModelForm (): CarModel {
  return {
    '@id': '',
    model: '',
    carBrand: undefined,
    carEngines: [],
    carFinishes: [],
  }
}

export interface CarModelFormState {
  carModel: CarModel
  isChanged: boolean
  isSubmitting: boolean
}

@Module({ name: 'carModelForm', namespaced: true })
class CarModelFormModule extends VuexModule implements CarModelFormState {
  public carModel: CarModel = newCarModelForm()
  public isChanged = false
  public isSubmitting = false

  @Mutation
  public setCarModel (carModel: CarModel): void {
    this.carModel = carModel
    this.isChanged = false
  }

  @Mutation
  public setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  public setIsSubmitting (isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting
  }

  @Mutation
  public addCarEngine (carEngine: CarEngine): void {
    if (!this.carModel.carEngines) {
      this.carModel.carEngines = []
    }
    this.carModel.carEngines.push(carEngine)
  }

  @Mutation
  public replaceCarEngine (carEngine: CarEngine): void {
    if (!this.carModel.carEngines) {
      return
    }
    const index = this.carModel.carEngines.findIndex((carEngineItem) => carEngineItem['@id'] === carEngine['@id'])
    this.carModel.carEngines.splice(index, 1, carEngine)
  }

  @Mutation
  public addCarFinish (carFinish: CarFinish): void {
    if (!this.carModel.carFinishes) {
      this.carModel.carFinishes = []
    }
    this.carModel.carFinishes.push(carFinish)
  }

  @Mutation
  public replaceCarFinish (carFinish: CarFinish): void {
    if (!this.carModel.carFinishes) {
      return
    }
    const index = this.carModel.carFinishes.findIndex((carFinishItem) => carFinishItem['@id'] === carFinish['@id'])
    this.carModel.carFinishes.splice(index, 1, carFinish)
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<CarModel> {
    const response = await http.get(`/api/car_models/${id}`)
    const carModel = response.data as CarModel
    this.context.commit('setCarModel', carModel)
    return carModel
  }

  @Action({ rawError: true })
  public async save (): Promise<CarModel> {
    this.context.commit('setIsSubmitting', true)
    try {
      const carModel: CarModel = cloneObjectReplaceSubObjectByIRI(this.carModel)
      if (this.carModel['@id']) {
        const response = await http.put(this.carModel['@id'], carModel)
        this.context.commit('setCarModel', response.data)
      } else {
        const response = await http.post('/api/car_models', carModel)
        this.context.commit('setCarModel', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitting', false)
    }
    return this.carModel
  }

  @Action({ rawError: true })
  public async reset (): Promise<CarModel> {
    const carModel = newCarModelForm()
    this.context.commit('setCarModel', carModel)
    this.context.commit('setIsSubmitting', false)
    return carModel
  }
}

export default CarModelFormModule
