import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { Configuration } from '@/api/interfaces/configuration'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { Brand } from '@/api/enums/brand'

export interface ConfigurationState {
  list: Array<Configuration>
  loading: boolean,
}

@Module({ name: 'configuration', namespaced: true })
class ConfigurationModule extends VuexModule implements ConfigurationState {
  public list: Array<Configuration> = [];
  public loading = false;

  @Mutation
  public setList (list: Array<Configuration>): void {
    this.list = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<Configuration>> {
    this.context.commit('setLoading')
    const response = await http.get('/api/configurations')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Configuration>
  }

  @Action({ rawError: true })
  public async getEmailSenderByNeedTraining (needTraining: NeedTraining): Promise<string> {
    if (
      needTraining.trainingType &&
      (needTraining.trainingType as TrainingType)?.specificToyota
    ) {
      const brand = needTraining.carBrand?.brand.toUpperCase()
      if (brand in Brand) {
        const configuration = await this.getConfigurationByCode(`email_${brand.toLowerCase()}`)
        if (configuration && configuration.value) {
          return configuration.value
        }
      }
    }

    return (await this.getConfigurationByCode('email_contact') as Configuration).value as string
  }

  @Action({ rawError: true })
  public async getEmailSenderByTrainingSession (session: {
    trainingSession: TrainingSession,
    logisticSession: LogisticSession,
  }): Promise<string> {
    const trainingSession = session.trainingSession
    const logisticSession = session.logisticSession
    if (
        trainingSession.trainingType &&
        (trainingSession.trainingType as TrainingType)?.specificToyota
    ) {
      for (const vehicle of logisticSession.vehicles) {
        const brand = vehicle.carBrand?.brand.toUpperCase()
        if (!(brand in Brand)) {
          continue
        }
        const configuration = await this.getConfigurationByCode(`email_${brand.toLowerCase()}`)
        if (configuration && configuration.value) {
          return configuration.value
        }
      }
    }

    return (await this.getConfigurationByCode('email_contact') as Configuration).value as string
  }

  @Action({ rawError: true })
  public getConfigurationById (id: number): Configuration | null {
    return this.list.find((item: Configuration) => item.id === id) || null
  }

  @Action({ rawError: true })
  private getConfigurationByCode (code: string): Configuration | null {
    return this.list.find((item: Configuration) => item.code === code) || null
  }
}

export default ConfigurationModule
