/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Trainee } from '@/api/interfaces/trainee'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
export interface TraineeFormState {
  trainee: Trainee
  isChanged: boolean
  isSubmitted: boolean
}

function newTrainee (): Trainee {
  return {
      '@id': '',
      civility: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      mobilePhone: '',
      email: '',
      firstNameDeliveryImported: '',
      lastNameDeliveryImported: '',
      phoneNumberDeliveryImported: '',
      mobilePhoneDeliveryImported: '',
      emailDeliveryImported: '',
      area: undefined,
  }
}

@Module({ name: 'traineeForm', namespaced: true })
class TraineeFormModule extends VuexModule implements TraineeFormState {
  public trainee: Trainee = newTrainee()
  public isChanged = false
  public isSubmitted = false

  @Mutation
  public setTrainee (trainee: Trainee): void {
    this.trainee = trainee
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmitted = submited
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Trainee> {
    const response = await http.get(`/api/trainees/${id}`)
    const trainee = response.data as Trainee
    this.context.commit('setTrainee', trainee)
    return trainee
  }

  @Action({ rawError: true })
  public async save (): Promise<Trainee> {
    this.context.commit('setIsSubmitted', true)
    try {
      const trainee: Trainee = cloneObjectReplaceSubObjectByIRI(this.trainee)
      if (this.trainee['@id']) {
        const response = await http.put(this.trainee['@id'], trainee)
        this.context.commit('setTrainee', response.data)
      } else {
        const response = await http.post('/api/trainees', trainee)
        const newTrainee: Trainee = response.data
        this.context.commit('setTrainee', newTrainee)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.trainee
  }

  @Action({ rawError: true })
  public async reset (): Promise<Trainee> {
    const trainee = newTrainee()
    this.context.commit('setTrainee', trainee)
    this.context.commit('setIsSubmitted', false)
    return trainee
  }
}

export default TraineeFormModule
