export enum NeedTrainingStatus {
  INIT = 'init',
  ELIGIBLE = 'eligible',
  POCKET = 'pocket',
  ALERTE_IMPORT = 'alerte_import',
  A_CONTACTER = 'a_contacter',
  EN_ATTENTE_COORDONNEES = 'attente_coordonnees',
  OK_ATTENTE_VALIDATION_DATE = 'ok_attente_validation_date',
  OK_ATTENTE_COORD_CONDUCTEUR = 'ok_attente_coord_conducteur',
  OK_ATTENTE_DE_RELANCE = 'ok_attente_de_relance',
  OK_ATTENTE_LIVRAISON = 'ok_attente_livraison',
  PLANIFIE = 'planifie',
  REFUS = 'refus',
  ABSENCE_DE_REPONSE = 'absence_reponse',
  FORME = 'forme',
  ABSENT = 'absent',
  PERTE_DE_DROITS = 'perte_de_droit',
}
