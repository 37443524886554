











































import { defineComponent } from '@vue/composition-api'
import Card from './Card.vue'

export default defineComponent({
  name: 'MaterialStatsCard',

  inheritAttrs: false,

  props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(Card as any).props,
    icon: {
      type: String,
      required: true,
    },
    subIcon: {
      type: String,
      default: undefined,
    },
    subIconColor: {
      type: String,
      default: undefined,
    },
    subTextColor: {
      type: String,
      default: undefined,
    },
    subText: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    smallValue: {
      type: String,
      default: undefined,
    },
  },
})
