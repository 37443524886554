/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CompanyContact } from '@/api/interfaces/companyContact'
import http from '@/plugins/axios'
import { Company } from '@/api/interfaces/company'

export interface CompanyContactListState {
  list: Array<CompanyContact>
  totalItems: number
}

export function newCompanyContact (): CompanyContact {
  return {
    '@id': '',
    profession: undefined,
    civility: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    company: '',
    main: false,
  }
}

@Module({ name: 'companyContactList', namespaced: true })
class CompanyContactListModule extends VuexModule implements CompanyContactListState {
  public list: Array<CompanyContact> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CompanyContact>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public addCompanyContact (companyContact: CompanyContact): void {
    this.list.push(companyContact)
    this.totalItems++
  }

  @Mutation
  public replaceCompanyContact (companyContact: CompanyContact): void {
    const position = this.list.findIndex(
      element => element['@id'] === companyContact['@id'],
    )
    if (this.list[position]) {
      this.list[position] = companyContact
    }
  }

  @Action({ rawError: true })
  public reset (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
  }

  @Action({ rawError: true })
  public async load (
    company: Company | Array<Company>,
  ): Promise<Array<CompanyContact>> {
    let response
    if (Array.isArray(company)) {
      let companiesRequest = '?'
      company.map(function (company: Company) {
        if (company['@id']) {
          companiesRequest += 'company[]=' + company['@id'] + '&'
        }
      })
      companiesRequest = companiesRequest.slice(0, -1)

      response = await http.get('/api/company_contacts' + companiesRequest)
    } else {
      response = await http.get(`/api/company_contacts?company=${company.id}`)
    }
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CompanyContact>
  }

  @Action({ rawError: true })
  public async post (companyContact: CompanyContact): Promise<CompanyContact> {
    const response = await http.post('/api/company_contacts', companyContact)
    this.context.commit('addCompanyContact', response.data)
    return response.data as CompanyContact
  }

  @Action({ rawError: true })
  public async put (companyContact: CompanyContact): Promise<CompanyContact> {
    if (companyContact['@id']) {
      const response = await http.put(companyContact['@id'], companyContact)
      this.context.commit('replaceCompanyContact', response.data)
      return response.data as CompanyContact
    }
    return this.post(companyContact)
  }
}

export default CompanyContactListModule
