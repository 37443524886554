
























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VComponent',

  props: {
    heading: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
})
