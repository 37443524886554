/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { EmailSessionDocument } from '@/api/interfaces/emailsessiondocument'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface EmailSessionDocumentState {
  emailSessionDocument: EmailSessionDocument,
  isSubmitted: boolean,
}

function newEmailSessionDocument (): EmailSessionDocument {
  return {
    blindCarbonCopy: [],
    carbonCopy: [],
    content: '',
    needTraining: '',
    receiver: '',
    sender: '',
    sentAt: undefined,
    title: '',
    trainingSession: '',
    typeDocument: '',
  }
}

@Module({ name: 'TrainerDocumentType', namespaced: true })
class EmailSessionDocumentModule extends VuexModule implements EmailSessionDocumentState {
  public emailSessionDocument: EmailSessionDocument = newEmailSessionDocument()
  public isSubmitted = false

  @Mutation
  public setEmailSessionDocument (emailSessionDocument: EmailSessionDocument): void {
    this.emailSessionDocument = emailSessionDocument
  }

  @Mutation
  public setIsSubmitting (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Action({ rawError: true })
  public async save (emailSessionDocument: EmailSessionDocument): Promise<EmailSessionDocument> {
    this.context.commit('setIsSubmitting', true)
    try {
      emailSessionDocument = cloneObjectReplaceSubObjectByIRI(emailSessionDocument)
      await http.post('/api/email_session_documents', emailSessionDocument)
    } finally {
      this.context.commit('setEmailSessionDocument', emailSessionDocument)
      this.context.commit('setIsSubmitting', false)
    }
    return this.emailSessionDocument
  }

  @Action({ rawError: true })
  public async loadByTrainingSession (idTrainingSession: number): Promise<Array<EmailSessionDocument>> {
    const response = await http.get(`/api/email_session_documents/training_sessions/${idTrainingSession}`)
    return response.data['hydra:member'] as Array<EmailSessionDocument>
  }
}

export default EmailSessionDocumentModule
