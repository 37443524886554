/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TrainerDocumentType } from '@/api/interfaces/trainerdocumenttype'
import http from '@/plugins/axios'

export interface TrainerDocumentTypeState {
  list: Array<TrainerDocumentType>
  totalItems: number
}

@Module({ name: 'TrainerDocumentType', namespaced: true })
class TrainerDocumentTypeModule extends VuexModule implements TrainerDocumentTypeState {
  public list: Array<TrainerDocumentType> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<TrainerDocumentType>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<TrainerDocumentType>> {
    const response = await http.get('/api/trainer_document_types')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<TrainerDocumentType>
  }
}

export default TrainerDocumentTypeModule
