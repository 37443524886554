import { EventDay } from '@/api/interfaces/eventday'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface EventDayState {
  list: Array<EventDay>
  totalItems: number
  loading: boolean
}

@Module({ name: 'eventDay', namespaced: true })
class EventDayModule extends VuexModule implements EventDayState {
  public list: Array<EventDay> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<EventDay>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (totalItems: number): void {
    this.totalItems = totalItems
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<EventDay>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/event_days${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<EventDay>
  }

  @Action({ rawError: true })
  public async delete (id: number): Promise<void> {
    this.context.commit('setLoading')
    await http.delete(`api/event_days/${id}`)
    this.context.commit('setList', this.list.filter((eventDay: EventDay) => eventDay.id !== id))
    this.context.commit('setTotalItems', this.totalItems - 1)
    this.context.commit('setLoading')
  }

  get eventDays (): Array<EventDay> {
    return this.list
  }
}

export default EventDayModule
