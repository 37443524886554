/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Company } from '@/api/interfaces/company'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { CompanyContact } from '@/api/interfaces/companyContact'
import { companyContactListStore } from '@/store'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { Contact, InfoLegales } from '@/views/dashboard/company/store/apiCompany'
import CodesNaf from '@socialgouv/codes-naf'

export interface CompanyFormState {
  company: Company,
  isChanged: boolean,
  isSubmitted: boolean,
}

function newCompany (): Company {
  return {
    '@id': '',
    name: '',
    nameComplementary: '',
    city: '',
    companyContacts: [],
    address: '',
    clientType: 'Professionnels',
    type: '',
    zipCode: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    website: '',
    siret: '',
    businessIntroducer: false,
    commentary: '',
    accountType: undefined,
    trainingOfficersDriverProgram: [],
    trainingOfficersOthers: [],
    commercials: [],
    relationClient: undefined,
    fleetSize: undefined,
    bankAccount: '',
    paymentPeriod: undefined,
    nafCode: undefined,
  }
}

@Module({ name: 'companyForm', namespaced: true })
class CompanyFormModule extends VuexModule implements CompanyFormState {
  public company: Company = newCompany()
  public isChanged = false
  public isSubmitted = false

  @Mutation
  public setCompany (company: Company): void {
    this.company = company
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmitted = submited
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Company> {
    const response = await http.get(`/api/companies/${id}`)
    const company = response.data as Company
    this.context.commit('setCompany', company)
    return company
  }

  @Mutation
  public addCompanyContact (companyContact: CompanyContact): void {
    if (!this.company.companyContacts) {
      this.company.companyContacts = []
    }
    this.company.companyContacts.push(companyContact)
  }

  @Mutation
  public updateMainManager (companyContacts: Array<CompanyContact>): void {
    this.company.companyContacts = companyContacts
    this.isChanged = true
  }

  @Action({ rawError: true })
  public async save (): Promise<Company> {
    this.context.commit('setIsSubmitted', true)
    try {
      const company: Company = cloneObjectReplaceSubObjectByIRI(this.company)
      if (this.company['@id']) {
        const response = await http.put(this.company['@id'], company)
        this.context.commit('setCompany', response.data)
      } else {
        const response = await http.post('/api/companies', company)
        // save companyContacts
        const iriCompany = response.data['@id']
        if (this.company.companyContacts) {
          this.company.companyContacts.forEach(async (manager: CompanyContact) => {
            manager.company = iriCompany
            await companyContactListStore.post(manager)
          })
        }
        this.context.commit('setCompany', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.company
  }

  @Action({ rawError: true })
  public async reset (): Promise<Company> {
    const company = newCompany()
    this.context.commit('setCompany', company)
    this.context.commit('setIsSubmitted', false)
    return company
  }

  @Action({ rawError: true })
  public async setMainManager (mainManager: CompanyContact): Promise<Company> {
    if (mainManager?.id) {
      const response = await http.put(`${this.company['@id']}/set_main_manager`, { mainManagerId: mainManager.id })
      this.context.commit('updateMainManager', response.data.companyContacts)
    } else {
      const companyContacts = this.company?.companyContacts
      if (companyContacts) {
        companyContacts.forEach((manager: CompanyContact) => {
          manager.main = false
        })
      }
      mainManager.main = true
    }

    return this.company
  }

  @Action({ rawError: true })
  setCompanyFromApi (data: { infoLegales : InfoLegales, contact: Contact }): void {
    const company = newCompany()
    company.siret = data.infoLegales.siretsiegeformat
    company.name = data.infoLegales.denoinsee
    company.address = data.infoLegales.voieadressageinsee
    company.zipCode = data.infoLegales.codepostalinsee
    company.city = data.infoLegales.villeinsee
    company.nafCode = CodesNaf.find((codeNaf: CodeNaf) => codeNaf.label === data.infoLegales.naflibinsee)?.id
    company.phoneNumber = data.contact.tel
    company.email = data.contact.email
    company.website = data.contact.siteweb

    this.context.commit('setCompany', company)
  }
}

export default CompanyFormModule
