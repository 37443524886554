








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ItemSubGroup',

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
  },
})
