
































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MaterialChartCard',

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    eventHandlers: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    ratio: {
      type: String,
      default: undefined,
    },
    responsiveOptions: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      required: true,
      validator: (v:string) => ['Bar', 'Line', 'Pie'].includes(v),
    },
  },
})
