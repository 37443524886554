/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { Renter } from '@/api/interfaces/renter'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface ManagerListState {
  list: Array<Renter>
  totalItems: number
}

export function newRenter (): Renter {
  return {
    '@id': '',
    name: '',
    address: '',
    city: '',
    zipCode: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
  }
}

@Module({ name: 'renterList', namespaced: true })
class RenterModule extends VuexModule implements ManagerListState {
  public list: Array<Renter> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<Renter>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public addRenter (renter: Renter): void {
    this.list.push(renter)
    this.totalItems++
  }

  @Mutation
  public replaceRenter (renter: Renter): void {
    const position = this.list.findIndex(element => element['@id'] === renter['@id'])
    if (this.list[position]) {
      this.list[position] = renter
    }
  }

  @Action({ rawError: true })
  public reset (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Renter>> {
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/renters${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<Renter>
  }

  @Action({ rawError: true })
  public async post (renter : Renter): Promise<Renter> {
    const response = await http.post('/api/renters', renter)
    this.context.commit('addRenter', response.data)
    return response.data as Renter
  }

  @Action({ rawError: true })
  public async put (renter: Renter): Promise<Renter> {
    if (renter['@id']) {
      const response = await http.put(renter['@id'], renter)
      this.context.commit('replaceRenter', response.data)
      return response.data as Renter
    }
    return this.post(renter)
  }
}

export default RenterModule
