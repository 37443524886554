/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import { Company } from '@/api/interfaces/company'
import { CarDealer } from '@/api/interfaces/cardealer'

export interface FiltersParams {
  businessIntroducer: number[] | null
  company: number[] | null
  trainingType: string[] | null
  status: string[] | null
  carDealer: number[] | null
  'company.accountType': string | null
  commandNoOneOrNoTwo: string | null
  TrainingSessionStartAt: Date | null
  'trainee.lastName': string | null
  'trainee.firstName': string | null
  'trainee.phones': string | null
  'trainee.email': string | null
  traineeImport: number | null
  carNumberPlate: string | null
  'exists[sendToCallCenterAt]': boolean | null
}
export interface FilterListState {
  trainee: {
    lastName: string
    firstName: string
    phones: string
    email: string
  }
  commandNoOneOrNoTwo: string | null
  businessIntroducer: Company[] | null
  company: Company[] | null
  trainingType: string[] | null
  status: string[] | null
  carDealer: CarDealer[] | null
  accountType: string | null
  trainingSession: {
    startAt: Date | null
  },
  traineeImport: number | null
  carNumberPlate: string | null
  exists: {
    sendToCallCenterAt: boolean | null
  },
}
function newFilterList (): FilterListState {
  return {
    trainee: {
      lastName: '',
      firstName: '',
      phones: '',
      email: '',
    },
    commandNoOneOrNoTwo: null,
    businessIntroducer: null,
    company: null,
    trainingType: null,
    status: null,
    carDealer: null,
    accountType: null,
    trainingSession: {
      startAt: null,
    },
    traineeImport: null,
    carNumberPlate: '',
    exists: {
      sendToCallCenterAt: null,
    },
  }
}
@Module({ name: 'filterList', namespaced: true })
class FilterListModule extends VuexModule implements FilterListState {
  trainee!: { lastName: string; phones: string; firstName: string; email: string }
  commandNoOneOrNoTwo!: string | null
  businessIntroducer!: Company[] | null
  company!: Company[] | null
  trainingType!: string[] | null
  status!: string[] | null
  carDealer!: CarDealer[] | null
  accountType!: string | null
  trainingSession!: { startAt: Date | null }
  traineeImport!: number | null
  carNumberPlate!: string | null
  exists!: { sendToCallCenterAt: boolean | null };

  public filter: FilterListState = newFilterList()

  @Mutation
  updateField (options: { path: 'string'; value: unknown }) {
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getFiltersParams (): FiltersParams {
    return {
      businessIntroducer: this.filter.businessIntroducer?.map(businessIntroducer => businessIntroducer.id as number) || null,
      company: this.filter.company?.map(company => company.id as number) || null,
      trainingType: this.filter.trainingType || null,
      status: this.filter.status || null,
      carDealer: this.filter.carDealer?.map(carDealer => carDealer.id as number) || null,
      'company.accountType': this.filter.accountType || null,
      commandNoOneOrNoTwo: this.filter.commandNoOneOrNoTwo || null,
      // FIXME: not sure if it's working
      TrainingSessionStartAt: this.filter.trainingSession?.startAt || null,
      'trainee.lastName': this.filter.trainee.lastName || null,
      'trainee.firstName': this.filter.trainee.firstName || null,
      'trainee.phones': this.filter.trainee.phones || null,
      'trainee.email': this.filter.trainee.email || null,
      traineeImport: this.filter.traineeImport || null,
      carNumberPlate: this.filter.carNumberPlate || null,
      'exists[sendToCallCenterAt]': this.filter.exists.sendToCallCenterAt,
    }
  }
}

export default FilterListModule
