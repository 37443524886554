import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { ApiLog } from '@/api/interfaces/apiLog'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface ApiLogState {
  list: Array<ApiLog>
  totalItems: number
  loading: boolean
}

@Module({ name: 'apiLog', namespaced: true })
class ApiLogModule extends VuexModule implements ApiLogState {
  public list: Array<ApiLog> = [];
  public totalItems = 0;
  public loading = false;

  @Mutation
  public setList (list: Array<ApiLog>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<ApiLog>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/api_logs${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<ApiLog>
  }
}

export default ApiLogModule
