/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarDealer } from '@/api/interfaces/cardealer'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface CarDealerFormState {
  carDealer: CarDealer
  isChanged: boolean
  isSubmitting: boolean
}

export function newCarDealer (): CarDealer {
  return {
      '@id': '',
      code: '',
      name: '',
      carDealerRegion: '',
      brand: null,
      address: undefined,
      regionRencontres: undefined,
      location: undefined,
  }
}

@Module({ name: 'carDealerForm', namespaced: true })
class CarDealerFormModule extends VuexModule implements CarDealerFormState {
  public carDealer = newCarDealer()
  public isChanged = false
  public isSubmitting = false

  @Mutation
  public setCarDealer (carDealer: CarDealer): void {
    this.carDealer = carDealer
    this.isChanged = false
  }

  @Mutation
  public setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  public setIsSubmitting (isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<CarDealer> {
    const response = await http.get(`/api/car_dealers/${id}`)
    const carDealer = response.data as CarDealer
    this.context.commit('setCarDealer', carDealer)
    return carDealer
  }

  @Action({ rawError: true })
  public async save (): Promise<CarDealer> {
    this.context.commit('setIsSubmitting', true)
    try {
      const carDealer: CarDealer = cloneObjectReplaceSubObjectByIRI(this.carDealer)
      if (this.carDealer['@id']) {
        const response = await http.put(this.carDealer['@id'], carDealer)
        this.context.commit('setCarDealer', response.data)
      } else {
        const response = await http.post('/api/car_dealers', carDealer)
        this.context.commit('setCarDealer', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitting', false)
    }
    return this.carDealer
  }

  @Action({ rawError: true })
  public async reset (): Promise<CarDealer> {
    const carDealer = newCarDealer()
    this.context.commit('setCarDealer', carDealer)
    this.context.commit('setIsSubmitting', false)
    return carDealer
  }
}

export default CarDealerFormModule
