/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Trainer } from '@/api/interfaces/trainer'
import { TrainerDocument } from '@/api/interfaces/trainerdocument'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface TrainerFormState {
  trainer: Trainer
  isChanged: boolean
  isSubmitted: boolean
}

function newTrainer (): Trainer {
  return {
    '@id': '',
    civility: '',
    firstName: '',
    lastName: '',
    corporateName: '',
    address: '',
    zipCode: '',
    city: '',
    areas: [],
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    badge: '',
    trainerDocuments: [],
    trainingType: [],
    commentary: '',
    password: '',
    isActive: false,
    roles: [],
  }
}

@Module({ name: 'trainerForm', namespaced: true })
class TrainerFormModule extends VuexModule implements TrainerFormState {
  public trainer: Trainer = newTrainer()
  public isChanged = false
  public isSubmitted = false

  @Mutation
  public setTrainer (trainer: Trainer): void {
    this.trainer = trainer
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmitted = submited
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Trainer> {
    const response = await http.get(`/api/trainers/${id}`)
    const trainer = response.data as Trainer
    this.context.commit('setTrainer', trainer)
    return trainer
  }

  @Action({ rawError: true })
  public async upload (document: TrainerDocument): Promise<any> {
    // document format
    // { trainer: ,
    // file: ,
    // trainerDocumentType: ,}
    const response: any = await http.post(
      '/api/trainer_documents/upload',
      document,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    this.context.dispatch('loadById', this.trainer.id)
    // return response.data as TraineeImport
  }

  @Action({ rawError: true })
  public async save (): Promise<Trainer> {
    this.context.commit('setIsSubmitted', true)
    try {
      const trainer: Trainer = cloneObjectReplaceSubObjectByIRI(this.trainer)
      if (this.trainer['@id']) {
        const response = await http.put(this.trainer['@id'], trainer)
        this.context.commit('setTrainer', response.data)
      } else {
        const response = await http.post('/api/trainers', trainer)
        this.context.commit('setTrainer', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.trainer
  }

  @Action({ rawError: true })
  public async reset (): Promise<Trainer> {
    const trainer = newTrainer()
    this.context.commit('setTrainer', trainer)
    this.context.commit('setIsSubmitted', false)
    return trainer
  }

  @Action({ rawError: true })
  public async deleteDocument (documentId: number): Promise<Trainer> {
    return await http.delete(`/api/trainer_documents/${documentId}`)
  }

  @Action({ rawError: true })
  public async downloadDocument (trainerDocument: TrainerDocument) {
    const response = await http.get(
      `/api/trainer_documents/${trainerDocument.id}/download`,
      { responseType: 'arraybuffer' },
    )
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/octet-binary' }),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${trainerDocument.fileName}`)
    document.body.appendChild(link)
    link.click()
  }
}

export default TrainerFormModule
