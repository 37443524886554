/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { Trainee } from '@/api/interfaces/trainee'

export interface generateState {
  idTrainingSession: string | null
  idSessionDocuments: string | null,
  percentage: number | null,
  mentionClimat: Array<Trainee>,
}

@Module({ name: 'import', namespaced: true })
class generateModule extends VuexModule implements generateState {
  public idTrainingSession: string | null = null
  public idSessionDocuments: string | null = null
  public percentage: number | null = 0
  public mentionClimat = []

  @Mutation
  public setIdTrainingSession (id: string): void {
    this.idTrainingSession = id
  }

  @Mutation
  public setIdSessionDocuments (id: string): void {
    this.idSessionDocuments = id
  }

  @Mutation
  public setMentionClimat (trainee: Trainee): void {
    const index = this.mentionClimat.findIndex((t: Trainee) => t.id === trainee.id)
    if (index === -1) {
      this.mentionClimat.push(trainee as never)
    } else {
      this.mentionClimat.splice(index, 1)
    }
  }

  @Mutation
  public setPercentage (percentage: number): void {
    this.percentage = percentage
  }

  @Action({ rawError: true })
  public async launchGenerateDocument (params: { idSession: string, mentionClimat: Array<Trainee>}): Promise<string> {
    const arrayMentionClimat = params.mentionClimat.map((trainee: Trainee) => trainee.id)
    const response = await http.post(`print/training_sessions/${params.idSession}/documents_mass_generation/attestation`, { mentionClimat: arrayMentionClimat })
    this.context.commit('setIdTrainingSession', params.idSession)
    this.context.commit('setIdSessionDocuments', response.data)
    return response.data
  }

  @Action({ rawError: true })
  public async getAdvancement (idSessionDocuments: string): Promise<string> {
    const response = await http.get(`api/training_session_documents/${idSessionDocuments}`)
    const percentage = Math.round(response.data.nbDocumentsGenerated / response.data.nbDocuments * 100) || 0
    this.context.commit('setPercentage', percentage)

    return response.data
  }

  @Action({ rawError: true })
  public addMentionClimat (trainee: Trainee): void {
    this.context.commit('setMentionClimat', trainee)
  }
}

export default generateModule
