import { User } from '@/api/interfaces/user'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface UserListState {
  list: Array<User>
  totalItems: number
  loading: boolean
}

@Module({ name: 'UserList', namespaced: true })
class UserListModule extends VuexModule
  implements UserListState {
  list: Array<User> = []
  loading = false
  totalItems = 0

  @Mutation
  public setList (list: Array<User>): void {
    this.list = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<User>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/users${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<User>
  }
}

export default UserListModule
