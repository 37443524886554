/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarColor } from '@/api/interfaces/carcolor'
import http from '@/plugins/axios'

export interface CarColorState {
  list: Array<CarColor>
  totalItems: number
}

function newCarColor (): CarColor {
  return {
      '@id': '',
      color: '',
  }
}

@Module({ name: 'CarColor', namespaced: true })
class CarColorModule extends VuexModule implements CarColorState {
  public list: Array<CarColor> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarColor>): void {
    this.list = list
  }

  @Mutation
  public addColor (color: CarColor): void {
    this.list.push(color)
    this.totalItems++
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<CarColor>> {
    const response = await http.get('/api/car_colors')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarColor>
  }

  @Action({ rawError: true })
  public async save (color: string): Promise<CarColor> {
    const colorObject = newCarColor()
    colorObject.color = color
    const response = await http.post('/api/car_colors', colorObject)
    this.context.commit('addColor', response.data)
    return response.data as CarColor
  }
}

export default CarColorModule
