





















































import { defineComponent } from '@vue/composition-api'

interface SelectItem {
  '@id': string
  label: string
}

export default defineComponent({
  name: 'ListSelectFilter',
  data () {
    return {
      filterValue: '',
    }
  },
  props: {
    translation: {
      type: String,
    },
    value: {
      type: [String, Array],
    },
    items: {
      type: Array,
    },
    itemValue: {
      type: Function,
      default: (item: SelectItem) => {
        return item['@id']
      },
    },
    itemText: {
      type: Function,
      default: (item: SelectItem) => {
        return item.label
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    (this.filterValue as unknown) = this.value
  },
  methods: {
    filter () {
      const value = this.filterValue
      this.$emit('filter-list', value)
    },
    clear () {
      this.filterValue = ''
      this.$emit('filter-list', '')
    },
  },
})
