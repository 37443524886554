/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { LogisticArea } from '@/api/interfaces/logisticArea'

export interface LogisticAreaState {
  list: Array<LogisticArea>
  totalItems: number
}

@Module({ name: 'LogisticArea', namespaced: true })
class LogisticAreaModule extends VuexModule implements LogisticAreaState {
  public list: Array<LogisticArea> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<LogisticArea>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<LogisticArea>> {
    const response = await http.get('/api/logistic_areas')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<LogisticArea>
  }
}

export default LogisticAreaModule
