/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import http from '@/plugins/axios'
import { Configuration } from '@/api/interfaces/configuration'

export interface ConfigurationFormState {
  configuration: Configuration
  isChanged: boolean
  isSubmitted: boolean
}

export function newConfiguration (): Configuration {
  return {
    '@id': '',
    code: '',
    value: '',
  }
}

@Module({ name: 'configurationForm', namespaced: true })
class ConfigurationFormModule extends VuexModule implements ConfigurationFormState {
  public configuration: Configuration = newConfiguration();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setConfiguration (configuration: Configuration): void {
    this.configuration = configuration
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean):void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getConfiguration (): Configuration {
    return this.configuration
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Configuration> {
    const response = await http.get(`/api/configurations/${id}`)
    const configuration = response.data as Configuration
    this.context.commit('setConfiguration', configuration)
    return configuration
  }

  @Action({ rawError: true })
  public async save (): Promise<Configuration> {
    this.context.commit('setIsSubmitted', true)
    try {
      const configuration: Configuration = cloneObjectReplaceSubObjectByIRI(this.configuration)
      const response = await http.put(configuration['@id'] as string, configuration)
      this.context.commit('setConfiguration', response.data)
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
     return this.configuration
  }

  @Action({ rawError: true })
  public async reset (): Promise<Configuration> {
    const configuration: Configuration = newConfiguration()
    this.context.commit('setConfiguration', configuration)
    this.context.commit('setIsSubmitted', false)
    return configuration
  }
}

export default ConfigurationFormModule
