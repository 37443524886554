/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Reminder } from '@/api/interfaces/reminder'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { FiltersParams } from '@/views/dashboard/trainee/store/filterList'

export interface ReminderFormState {
  reminder: Reminder
  isSubmited: boolean,
}

function newReminder (): Reminder {
  return {
      '@id': '',
      reminderAt: '',
      reminderType: '',
      commentary: '',
      needTraining: '',
  }
}

@Module({ name: 'reminderForm', namespaced: true })
class ReminderFormModule extends VuexModule implements ReminderFormState {
  public reminder: Reminder = newReminder();
  public isSubmited = false;

  @Mutation
  public setReminder (reminder: Reminder): void {
    this.reminder = reminder
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmited = submited
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Reminder> {
    const response = await http.get(`/api/reminders/${id}`)
    const reminder = response.data as Reminder
    this.context.commit('setReminder', reminder)
    return reminder
  }

  @Action({ rawError: true })
  public async save (reminder: Reminder): Promise<Reminder> {
    this.context.commit('setIsSubmitted', true)
    this.context.commit('setReminder', reminder)
    try {
      if (this.reminder['@id']) {
        const response = await http.put(this.reminder['@id'], this.reminder)
        this.context.commit('setReminder', response.data)
      } else {
        const response = await http.post('/api/reminders', this.reminder)
        const newReminder: Reminder = response.data
        this.context.commit('setReminder', newReminder)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.reminder
  }

  @Action({ rawError: true })
  public async massSave (params: {reminder: Reminder, filters: FiltersParams}): Promise<Reminder> {
    this.context.commit('setIsSubmitted', true)
    try {
      await http.post('/api/need_trainings/mass_send_reminder', params)
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.reminder
  }

  @Action({ rawError: true })
  public async reset (): Promise<Reminder> {
    const reminder = newReminder()
    this.context.commit('setReminder', reminder)
    this.context.commit('setIsSubmitted', false)
    return reminder
  }
}

export default ReminderFormModule
