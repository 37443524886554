/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { reminderFormStore, traineeFormStore, statusStore } from '@/store/store-accessor' // circular import; OK though
import { NeedTraining } from '@/api/interfaces/needtraining'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { Trainee } from '@/api/interfaces/trainee'
import { Reminder } from '@/api/interfaces/reminder'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface NeedTrainingFormState {
  needTraining: NeedTraining,
  isChanged: boolean,
  isSubmitted: boolean,
}

function newNeedTraining (): NeedTraining {
  return {
    '@id': '',
    status: '',
    trainingType: null,
    name: '',
    company: null,
    branch: null,
    businessIntroducer: null,
    commandNoOne: '',
    commandNoTwo: '',
    commandDate: null,
    renter: null,
    manager: null,
    carDealer: null,
    carDealerSeller: null,
    trainee: null,
    carBrand: null,
    carType: null,
    carColor: null,
    carEngine: null,
    carModel: null,
    carFinish: null,
    carNumberPlate: '',
    commentary: '',
    reminders: [],
    trainingSession: undefined,
    zipCode: '',
    city: '',
    estimatedDeliveryDate: null,
    deliveryDate: null,
    originDriverProgram: null,
    sendToCallCenterAt: null,
  }
}

@Module({ name: 'needTrainingForm', namespaced: true })
class NeedTrainingFormModule extends VuexModule implements NeedTrainingFormState {
  public needTraining: NeedTraining = newNeedTraining();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setNeedTraining (needTraining: NeedTraining): void {
    this.needTraining = needTraining
    this.isChanged = false
  }

  @Mutation
  public setTrainee (trainee: Trainee): void {
    this.needTraining.trainee = trainee
  }

  @Mutation
  public setTrainingType (trainingTypeIri: string): void {
    this.needTraining.trainingType = trainingTypeIri
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmitted = submited
  }

  @Mutation
  updateField (options: {path: 'string', value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  @Mutation
  public addReminder (reminder: Reminder): void {
  if (!this.needTraining.reminders) {
      this.needTraining.reminders = []
    }
    this.isChanged = true
    this.needTraining.reminders.push(reminder)
  }

  @Mutation
  public resetTrainingSession (): void {
    this.isChanged = true
    this.needTraining.trainingSession = null
  }

  @Mutation
  public associateTrainingSession (trainingSession: TrainingSession): void {
    this.isChanged = true
    this.needTraining.trainingSession = trainingSession
  }

  get getField () {
    return getField(this)
  }

  get getAssociatedTrainginSession () : TrainingSession {
    return this.needTraining.trainingSession
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<NeedTraining> {
    const response = await http.get(`/api/need_trainings/${id}`)
    this.context.commit('setNeedTraining', response.data)
    traineeFormStore.setTrainee(response.data.trainee)
    return response.data as NeedTraining
  }

  @Action({ rawError: true })
  public async save (): Promise<NeedTraining> {
    this.context.commit('setIsSubmitted', true)
    try {
      const needTraining: NeedTraining = cloneObjectReplaceSubObjectByIRI(this.needTraining)
      if (this.needTraining['@id']) {
        await http.put(this.needTraining['@id'], needTraining)
      } else {
        const response = await http.post('/api/need_trainings', needTraining)
        this.context.commit('setNeedTraining', response.data)
      }
      // save reminders
      if (this.needTraining.reminders) {
        this.needTraining.reminders.forEach(async (reminder: Reminder) => {
          if (!reminder['@id']) {
            reminder.needTraining = this.needTraining['@id']
            reminderFormStore.setReminder(reminder)
            await reminderFormStore.save(reminder)
          }
        })
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.needTraining
  }

  @Action({ rawError: true })
  public async reset (initValues: { trainee: Trainee|null; trainingType: string|null }): Promise<NeedTraining> {
    const needTraining:NeedTraining = newNeedTraining()
    needTraining.status = statusStore.getStatusInitIri
    needTraining.trainee = initValues.trainee
    needTraining.trainingType = initValues.trainingType
    this.context.commit('setNeedTraining', needTraining)
    this.context.commit('setIsSubmitted', false)
    return needTraining
  }
}

export default NeedTrainingFormModule
