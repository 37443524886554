/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TeachingTool } from '@/api/interfaces/teachingtool'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface TeachingToolFormState {
  teachingTool: TeachingTool
  isChanged: boolean,
  isSubmitted: boolean,
}

function newTeachingTool (): TeachingTool {
  return {
    '@id': '',
    name: '',
    reference: '',
    brand: '',
    serialNumber: '',
    purchaseDate: undefined,
    commentary: '',
    createdAt: undefined,
    area: null,
  }
}

@Module({ name: 'teachingToolForm', namespaced: true })
class TeachingToolFormModule extends VuexModule implements TeachingToolFormState {
  public teachingTool: TeachingTool = newTeachingTool();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setTeachingTool (teachingTool: TeachingTool): void {
    this.teachingTool = teachingTool
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<TeachingTool> {
    const response = await http.get(`/api/teaching_tools/${id}`)
    const teachingTool = response.data as TeachingTool
    this.context.commit('setTeachingTool', teachingTool)
    return teachingTool
  }

  @Action({ rawError: true })
  public async save (): Promise<TeachingTool> {
    this.context.commit('setIsSubmitted', true)
    try {
      const teachingTool: TeachingTool = cloneObjectReplaceSubObjectByIRI(this.teachingTool)
      if (this.teachingTool['@id']) {
        const response = await http.put(this.teachingTool['@id'], teachingTool)
        this.context.commit('setTeachingTool', response.data)
      } else {
        const response = await http.post('/api/teaching_tools', teachingTool)
        this.context.commit('setTeachingTool', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.teachingTool
  }

  @Action({ rawError: true })
  public async reset (): Promise<TeachingTool> {
    const teachingTool:TeachingTool = newTeachingTool()
    this.context.commit('setTeachingTool', teachingTool)
    this.context.commit('setIsSubmitted', false)
    return teachingTool
  }
}

export default TeachingToolFormModule
