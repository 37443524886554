import { format, formatISO } from 'date-fns'

export function useDate () {
  function createFormatISO (date: string | Date, hours = 0, minutes = 0) {
    return formatISO(new Date(date).setHours(hours, minutes, 0))
  }

  function getDateFormat (date: string, dateFormat: string) {
    return format(new Date(date), dateFormat)
  }

  function getLastMonthDayOfDate (date: string): string {
    return formatISO(new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0, 23, 59, 59))
  }

  return {
    createFormatISO,
    getDateFormat,
    getLastMonthDayOfDate,
  }
}
