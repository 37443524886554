/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarType } from '@/api/interfaces/cartype'
import http from '@/plugins/axios'

export interface CarTypeState {
  list: Array<CarType>
  totalItems: number
}

function newCarType (): CarType {
  return {
      '@id': '',
      type: '',
  }
}

@Module({ name: 'CarType', namespaced: true })
class CarTypeModule extends VuexModule implements CarTypeState {
  public list: Array<CarType> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarType>): void {
    this.list = list
  }

  @Mutation
  public addBrand (type: CarType): void {
    this.list.push(type)
    this.totalItems++
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<CarType>> {
    const response = await http.get('/api/car_types')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarType>
  }

  @Action({ rawError: true })
  public async save (type: string): Promise<CarType> {
    const typeObject = newCarType()
    typeObject.type = type
    const response = await http.post('/api/car_types', typeObject)
    this.context.commit('addBrand', response.data)
    return response.data as CarType
  }
}

export default CarTypeModule
