/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UserGroup } from '@/api/interfaces/userGroup'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { getField, updateField } from 'vuex-map-fields'
import { User } from '@/api/interfaces/user'

export interface UserGroupFormState {
  userGroup: UserGroup
  isChanged: boolean
  isSubmitted: boolean
}

export function newUserGroup (): UserGroup {
  return {
    '@id': '',
    name: '',
    roles: [],
  }
}

@Module({ name: 'userGroupForm', namespaced: true })
class UserGroupFormModule extends VuexModule implements UserGroupFormState {
  public userGroup: UserGroup = newUserGroup();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setUserGroup (userGroup: UserGroup): void {
    this.userGroup = userGroup
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  public setRoles (roles: Array<string>): void {
    this.isChanged = true
    this.userGroup.roles = roles
  }

  @Mutation
  public setUsers (users: Array<User>): void {
    this.isChanged = true
    this.userGroup.users = users
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<UserGroup> {
    const response = await http.get(`/api/user_groups/${id}`)
    const userGroup = response.data as UserGroup
    this.context.commit('setUserGroup', userGroup)
    return userGroup
  }

  @Action({ rawError: true })
  public async save (): Promise<UserGroup> {
    this.context.commit('setIsSubmitted', true)
    try {
      const userGroupToPost: UserGroup = cloneObjectReplaceSubObjectByIRI(this.userGroup)
      if (userGroupToPost['@id']) {
        const response = await http.put(userGroupToPost['@id'], userGroupToPost)
        this.context.commit('setUserGroup', response.data)
      } else {
        const response = await http.post('/api/user_groups', userGroupToPost)
        this.context.commit('setUserGroup', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }

    return this.userGroup
  }

  @Action({ rawError: true })
  public async reset (): Promise<UserGroup> {
    const userGroup:UserGroup = newUserGroup()
    this.context.commit('setUserGroup', userGroup)
    this.context.commit('setIsSubmitted', false)
    return userGroup
  }
}

export default UserGroupFormModule
