















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ListTextFilter',
  data () {
    return {
      filterValue: '',
    }
  },
  props: {
    translation: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
  },
  created () {
    this.filterValue = this.value
  },
  methods: {
    filter () {
      const value = this.filterValue
      this.$emit('filter-list', value)
    },
    clear () {
      this.filterValue = ''
      this.$emit('filter-list', '')
    },
  },
})
