import { Entity } from '@/api/interfaces/entity'

export function cloneObjectReplaceSubObjectByIRI<T extends Entity> (entity: T): T {
    const clonedEntity: Record<string, unknown> = {}
    for (const property in entity) {
      if (entity[property] !== null) {
        if (typeof entity[property] === 'object') {
            const subObject: Entity = entity[property]
            /** Replace array of object with array of IRI */
            if (Array.isArray(subObject) && subObject.length > 0) {
                clonedEntity[property as string] = subObject.map(
                  (item: { [item: string]: string }) => {
                    let iri = null
                    // eslint-disable-next-line no-prototype-builtins
                    if (item.hasOwnProperty('@id')) {
                      iri = item['@id']
                    } else {
                      iri = item
                    }

                    return iri
                  },
                )
            // eslint-disable-next-line no-prototype-builtins
            } else if (subObject.hasOwnProperty('@id')) {
                /** Replace objects with IRI by IRI, omit empty IRI */
                if (subObject['@id']) {
                    clonedEntity[property as string] = subObject['@id']
                }
            } else {
                /** let subobject witout IRI as is */
                clonedEntity[property as string] = subObject
            }
        } else {
            /** let non object properties as is */
            clonedEntity[property] = entity[property] as unknown
        }
      } else {
        clonedEntity[property as string] = null
      }
    }
    return clonedEntity as T
}

export interface QueryParams extends Record<string, unknown> {
  page: number,
  itemsPerPage: number,
  [sort: string]: unknown;
}

export function constructUrlQueryString (queryParams: QueryParams): string {
  const params = new URLSearchParams()
  for (const property in queryParams) {
    if ((property !== 'null') && (queryParams[property] || queryParams[property] === false)) {
      const subObject = queryParams[property] as Record<string, unknown>
      if (Array.isArray(subObject) && subObject.length !== 0) {
        for (const y in subObject) {
          const subSubValue = subObject[y] as unknown as string
          if (subSubValue) {
            params.append(property + '[]', subSubValue)
          }
        }
      } else if (typeof subObject === 'object' && subObject !== null) {
        for (const y in (subObject as Record<string, unknown>)) {
          const subSubValue = subObject[y] as string
          if (subSubValue) {
            params.append(property + '.' + y, subSubValue)
          }
        }
      } else {
        params.append(property, subObject as string)
      }
    }
  }
  let option = params.toString()
  option = option.length > 0 ? '?' + option : ''
  return option
}
