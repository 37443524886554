/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarFinish } from '@/api/interfaces/carfinish'
import { CarModel } from '@/api/interfaces/carmodel'
import http from '@/plugins/axios'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface CarFinishState {
  list: Array<CarFinish>
  totalItems: number
}

export function newCarFinish (): CarFinish {
  return {
      '@id': '',
      finish: '',
  }
}

@Module({ name: 'CarFinish', namespaced: true })
class CarFinishModule extends VuexModule implements CarFinishState {
  public list: Array<CarFinish> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarFinish>): void {
    this.list = list
  }

  @Mutation
  public addFinish (finish: CarFinish): void {
    this.list.push(finish)
    this.totalItems++
  }

  @Mutation
  public replaceCarFinish (carFinish: CarFinish): void {
    const position = this.list.findIndex(
      element => element['@id'] === carFinish['@id'],
    )
    if (this.list[position]) {
      this.list[position] = carFinish
    }
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (carModel: CarModel): Promise<Array<CarFinish>> {
    const response = await http.get(`/api/car_finishes?pagination=false&carModel=${carModel.id}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarFinish>
  }

  @Action({ rawError: true })
  public async save (carFinish: CarFinish): Promise<CarFinish> {
    const carFinishToSave: CarFinish = cloneObjectReplaceSubObjectByIRI(carFinish)
    if (carFinishToSave['@id']) {
      const response = await http.put(carFinishToSave['@id'], carFinishToSave)
      carFinish = response.data
      this.context.commit('replaceCarFinish', carFinish)
    } else {
      const response = await http.post('/api/car_finishes', carFinishToSave)
      carFinish = response.data
      this.context.commit('addFinish', carFinish)
    }
    return carFinish
  }
}

export default CarFinishModule
