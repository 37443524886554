























import { defineComponent } from '@vue/composition-api'
// Components
import { VSnackbar } from 'vuetify/lib'

declare module 'vue/types/vue' {
  interface VueConstructor {
    options: {
      computed: {
        classes: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          call: any
        }
      }
    }
  }
}

export default defineComponent({
  name: 'BaseMaterialSnackbar',

  extends: VSnackbar,

  props: {
    dismissible: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes () {
      return {
        ...VSnackbar.options.computed.classes.call(this),
        'v-snackbar--material': true,
      }
    },
  },
})
