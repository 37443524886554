export default class Query {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public start: string = '',
    public end: string = '',
    public currentDay: string = '',
    public logisticArea: string | null = null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) {}
}
