/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarDealerSeller } from '@/api/interfaces/cardealerseller'
import http from '@/plugins/axios'
import { CarDealer } from '@/api/interfaces/cardealer'

export interface CarDealerSellerListState {
  list: Array<CarDealerSeller>
  totalItems: number
}

export function newCarDealerSeller (): CarDealerSeller {
  return {
    '@id': '',
    civility: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
  }
}

@Module({ name: 'carDealerSellerList', namespaced: true })
class CarDealerSellerListModule extends VuexModule implements CarDealerSellerListState {
  public list: Array<CarDealerSeller> = [];
  public totalItems = 0;

  @Mutation
  public setList (list: Array<CarDealerSeller>): void {
    this.list = list
  }

  @Mutation
  public addCarDealerSeller (item: CarDealerSeller): void {
    this.list.push(item)
    this.totalItems++
  }

  @Mutation
  public replaceCarDealerSeller (carDealerSeller: CarDealerSeller): void {
    const position = this.list.findIndex(element => element['@id'] === carDealerSeller['@id'])
    if (this.list[position]) {
      this.list[position] = carDealerSeller
    }
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (carDealer: CarDealer): Promise<Array<CarDealerSeller>> {
    const response = await http.get(`/api/car_dealer_sellers?carDealer=${carDealer.id}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarDealerSeller>
  }

  @Action({ rawError: true })
  public async post (carDealerSeller: CarDealerSeller): Promise<CarDealerSeller> {
    const response = await http.post('/api/car_dealer_sellers', carDealerSeller)
    this.context.commit('addCarDealerSeller', response.data)
    return response.data as CarDealerSeller
  }

  @Action({ rawError: true })
  public async put (carDealerSeller: CarDealerSeller): Promise<CarDealerSeller> {
    if (carDealerSeller['@id']) {
      const response = await http.put(carDealerSeller['@id'], carDealerSeller)
      this.context.commit('replaceCarDealerSeller', response.data)
      return response.data as CarDealerSeller
    }
    return this.post(carDealerSeller)
  }
}

export default CarDealerSellerListModule
