/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Vehicle } from '@/api/interfaces/vehicle'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { formatISO } from 'date-fns'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface VehicleFormState {
  vehicle: Vehicle
  isChanged: boolean,
  isSubmitted: boolean,
}

function newVehicle (): Vehicle {
  return {
      '@id': '',
      carModel: null,
      carBrand: null,
      reference: '',
      matriculation: '',
      firstDateMatriculation: formatISO(new Date()),
      area: null,
      carColor: null,
      mileageStatementDate: null,
      carMileage: 0,
      fuel: '',
      renewAt: formatISO(new Date()),
      commentary: '',
      inactiveUntilDate: null,
      renter: null,
      category: '',
  }
}

@Module({ name: 'vehicleForm', namespaced: true })
class VehicleFormModule extends VuexModule implements VehicleFormState {
  public vehicle: Vehicle = newVehicle();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setVehicle (vehicle: Vehicle): void {
    this.vehicle = vehicle
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Vehicle> {
    const response = await http.get(`/api/vehicles/${id}`)
    const vehicle = response.data as Vehicle
    this.context.commit('setVehicle', vehicle)
    return vehicle
  }

  @Action({ rawError: true })
  public async save (): Promise<Vehicle> {
    this.context.commit('setIsSubmitted', true)
    try {
      const vehicle: Vehicle = cloneObjectReplaceSubObjectByIRI(this.vehicle)
      if (this.vehicle['@id']) {
        const response = await http.put(this.vehicle['@id'], vehicle)
        this.context.commit('setVehicle', response.data)
      } else {
        const response = await http.post('/api/vehicles', vehicle)
        this.context.commit('setVehicle', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.vehicle
  }

  @Action({ rawError: true })
  public async reset (): Promise<Vehicle> {
    const vehicle = newVehicle()
    this.context.commit('setVehicle', vehicle)
    this.context.commit('setIsSubmitted', false)
    return vehicle
  }
}

export default VehicleFormModule
