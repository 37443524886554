// Enum for listing variables usable in email templates for Convocation send by individual
export enum NotificationEmailConvocationIndividual {
  TRAINEE_FIRST_NAME = 'trainee_first_name',
  TRAINEE_LAST_NAME = 'trainee_last_name',
  TRAINING_TYPE_NAME = 'training_type_name',
  TRAINING_LOCATION_ADDRESS = 'training_location_address',
  TRAINING_LOCATION_CITY = 'training_location_city',
  TRAINING_LOCATION_ZIP_CODE = 'training_location_zip_code',
  TRAINING_SESSION_DATE = 'training_session_date',
  COMPANY_NAME = 'company_name',
}
