import axios from 'axios'
import store from '../store'

const config = {}
if (process.env.NODE_ENV === 'development') {
  config.baseURL = process.env.VUE_APP_BACKEND_BASE_URL
  config.withCredentials = true
}
config.headers = {
  Accept: 'application/ld+json',
}

const xhr = axios.create(config)

// Add a response interceptor
xhr.interceptors.response.use(
  response =>
    // Do something with response data
    response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
          store.dispatch('user/logout')
          store.dispatch('user/sendErrorMessage', 'api.disconnected')
          // TODO redirect to /login ?
      } else if (error.response.data.violations) {
        // handle violations message wihtout all the default message of symfony (which is not user friendly)
        store.dispatch('user/sendErrorMessage', error.response.data.violations[0].message)
      } else {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        store.dispatch('user/sendErrorMessage', error.response.data['hydra:title'] + '\n' + error.response.data['hydra:description'])
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      store.dispatch('user/sendErrorMessage', 'api.no-response')
    } else {
      // Something happened in setting up the request that triggered an Error
      store.dispatch('user/sendErrorMessage', 'api.init-request-error')
    }

    // Do something with response error
    return Promise.reject(error)
  },
)

export default xhr
