














import { defineComponent } from '@vue/composition-api'
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'

export default defineComponent({
  name: 'MaterialTabs',

  mixins: [Proxyable],

  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
})
