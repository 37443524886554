import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Profession } from '@/api/interfaces/profession'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { professionGestionnaire } from '@/config-constantes'

export interface ProfessionState {
  list: Array<Profession>
  totalItems: number
  totalItemsWithPagination: number
  loading: boolean
}

@Module({ name: 'Profession', namespaced: true })
class ProfessionModule extends VuexModule implements ProfessionState {
  public list: Array<Profession> = [];
  public listWithPagination: Array<Profession> = [];
  public totalItems = 0;
  public totalItemsWithPagination = 0;
  public loading = false

  @Mutation
  public setList (list: Array<Profession>): void {
    this.list = list
  }

  @Mutation
  public setListByPage (list: Array<Profession>): void {
    this.listWithPagination = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  get getProfessionGestionnaire (): Profession {
    return this.list.filter((profession: Profession) => profession.label === professionGestionnaire).shift() as Profession
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setTotalItemsWithPagination (total: number): void {
    this.totalItemsWithPagination = total
  }

  @Action({ rawError: true })
  public async load (
    queryParams: QueryParams,
  ): Promise<Array<Profession>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/professions${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Profession>
  }

  @Action({ rawError: true })
  public async loadWithPagination (
    queryParams: QueryParams,
  ): Promise<Array<Profession>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/professions${option}`)
    this.context.commit('setListByPage', response.data['hydra:member'])
    this.context.commit('setTotalItemsWithPagination', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Profession>
  }
}

export default ProfessionModule
