export enum StatusLogisticSessionCircuit {
  OPTION = 'option',
  VALIDATED = 'validated',
}

export interface ItemStatusLogisticSessionCircuit {
  text: string,
  value: string,
  class: string,
}
