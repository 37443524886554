
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Loading',

  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
  },
})
