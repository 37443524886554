import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Area } from '@/api/interfaces/area'
import http from '@/plugins/axios'

export interface AreaState {
  list: Array<Area>
  totalItems: number
  loading: boolean,
}

@Module({ name: 'area', namespaced: true })
class AreaModule extends VuexModule implements AreaState {
  public list: Array<Area> = [];
  public totalItems = 0;
  public loading = false;

  @Mutation
  public setList (list: Array<Area>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<Area>> {
    this.context.commit('setLoading')
    const response = await http.get('/api/areas')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Area>
  }
}

export default AreaModule
