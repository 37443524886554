import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { NeedTrainingStatus } from '@/api/interfaces/needtrainingstatus'
import http from '@/plugins/axios'

export interface StatusState {
  list: Array<NeedTrainingStatus>
  totalItems: number
}

@Module({ name: 'status', namespaced: true })
class StatusModule extends VuexModule implements StatusState {
  public list: Array<NeedTrainingStatus> = [];
  public totalItems = 0;
  public listWorkflow: Array<NeedTrainingStatus> = [];

  @Mutation
  public setList (list: Array<NeedTrainingStatus>): void {
    this.list = list
  }

  @Mutation
  public setListWorkflow (list: Array<NeedTrainingStatus>): void {
    this.listWorkflow = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<NeedTrainingStatus>> {
    const response = await http.get('/api/need_training_statuses')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<NeedTrainingStatus>
  }

  @Action({ rawError: true })
  public async loadWorkflow (idNeedTraining?: number): Promise<Array<NeedTrainingStatus>> {
    const route = idNeedTraining ? `/api/need_trainings/${idNeedTraining}/workflow_status` : '/api/need_trainings/workflow_status'
    const response = await http.get(route)
    this.context.commit('setListWorkflow', response.data['hydra:member'])
    return response.data['hydra:member'] as Array<NeedTrainingStatus>
  }

  get totalStatus (): number|undefined {
    return this.totalItems
  }

  get getStatusInitIri (): string | undefined {
    const initStatus = this.list.find(status => status.code === 'init')
    return initStatus ? initStatus['@id'] : undefined
  }

  get getStatusPocketIri (): string|undefined {
    const initStatus = this.list.find(status => status.code === 'pocket')
    return initStatus ? initStatus['@id'] : undefined
  }

  get getStatusAlertImportIri (): string|undefined {
    const alertImportStatus = this.list.find(status => status.code === 'alerte_import')
    return alertImportStatus ? alertImportStatus['@id'] : undefined
  }

  get getStatusEnAttenteCoordonneesIri (): string|undefined {
    const initStatus = this.list.find(status => status.code === 'attente_coordonnees')
    return initStatus ? initStatus['@id'] : undefined
  }
}

export default StatusModule
