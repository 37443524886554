/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { EventDay } from '@/api/interfaces/eventday'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import http from '@/plugins/axios'

export interface EventDayFormState {
  eventDay: EventDay,
  isChanged: boolean,
  isSubmitted: boolean,
}

function newEventDay (): EventDay {
  return {
    '@id': '',
    name: '',
    dateEvent: undefined,
    areas: [],
  }
}

@Module({ name: 'eventDayForm', namespaced: true })
class EventDayFormModule extends VuexModule implements EventDayFormState {
  eventDay = newEventDay()
  isChanged = false
  isSubmitted = false

  @Mutation
  public setEventDay (eventDay: EventDay): void {
    this.eventDay = eventDay
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async save (): Promise<EventDay> {
    this.context.commit('setIsSubmitted', true)
    try {
      const eventDayToPost: EventDay = cloneObjectReplaceSubObjectByIRI(this.eventDay)
      if (!eventDayToPost['@id']) {
        const response = await http.post('/api/event_days', eventDayToPost)
        this.context.commit('setEventDay', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.eventDay
  }

  @Action({ rawError: true })
  public async reset (): Promise<EventDay> {
    const eventDay: EventDay = newEventDay()
    this.context.commit('setEventDay', eventDay)
    this.context.commit('setIsSubmitted', false)
    return eventDay
  }
}

export default EventDayFormModule
