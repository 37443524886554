import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { FileEntity } from '@/api/interfaces/file'

export interface FileState {
  loading: boolean
  loadingDownload: boolean
}

@Module({ name: 'file', namespaced: true })
class FileModule extends VuexModule implements FileState {
  public loading = false
  public loadingDownload = false

  @Mutation
  public setLoading (loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setLoadingDownload (loading: boolean): void {
    this.loadingDownload = loading
  }

  @Action({ rawError: true })
  public async upload (file: File): Promise<FileEntity> {
    try {
      this.context.commit('setLoading', true)
      const formData = new FormData()
      formData.append('file', file)
      const response = await http.post(
        'api/files',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      return response.data as FileEntity
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action({ rawError: true })
  public async download (file: FileEntity): Promise<void> {
    try {
      this.context.commit('setLoadingDownload', true)
      const response = await http.get(`api/files/${file.id}`, {
        responseType: 'blob',
      })

      const fileBlob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${file.fileName}`)
      document.body.appendChild(link)
      link.click()
    } finally {
      this.context.commit('setLoadingDownload', false)
    }
  }
}

export default FileModule
