import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TrainingType } from '@/api/interfaces/trainingtype'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'

export interface TrainingTypeState {
  list: Array<TrainingType>
  totalItems: number
  loading: boolean
}

@Module({ name: 'TrainingType', namespaced: true })
class TrainingTypeModule extends VuexModule implements TrainingTypeState {
  public list: Array<TrainingType> = [];
  public listWithPagination: Array<TrainingType> = [];
  public totalItems = 0;
  public loading = false

  @Mutation
  public setList (list: Array<TrainingType>): void {
    this.list = list
  }

  @Mutation
  public setListByPage (list: Array<TrainingType>): void {
    this.listWithPagination = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  get getListActiveItems (): Array<TrainingType> {
    return this.list.filter((item) => item.isActive)
  }

  @Action({ rawError: true })
  public async load (
    queryParams: QueryParams,
  ): Promise<Array<TrainingType>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/training_types${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<TrainingType>
  }

  @Action({ rawError: true })
  public async loadWithPagination (
    queryParams: QueryParams,
  ): Promise<Array<TrainingType>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/training_types${option}`)
    this.context.commit('setListByPage', response.data['hydra:member'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<TrainingType>
  }
}

export default TrainingTypeModule
