








































import { defineComponent } from '@vue/composition-api'
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'

export default defineComponent({
  name: 'MaterialDropdown',

  mixins: [Proxyable],

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    items: {
      type: Array,
      default: () => ([
        {
          id: undefined,
          text: undefined,
        },
      ]),
    },
    transition: {
      type: String,
      default: 'scale-transition',
    },
  },
})
