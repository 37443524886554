/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'

export interface InfoLegales {
  siretsiegeformat: string,
  denoinsee: string,
  voieadressageinsee: string,
  codepostalinsee: string,
  villeinsee: string,
  naflibinsee: string,
}

export interface Contact {
  tel: string,
  email: string,
  siteweb: string,
}

export interface ApiCompanyState {
  infoLegales: InfoLegales,
  contact: Contact,
  isLoading: boolean,
}

@Module({ name: 'apiCompany', namespaced: true })
class ApiCompanyModule extends VuexModule implements ApiCompanyState {
  public infoLegales: InfoLegales = {
    siretsiegeformat: '',
    denoinsee: '',
    voieadressageinsee: '',
    codepostalinsee: '',
    villeinsee: '',
    naflibinsee: '',
  }

  public contact: Contact = {
    tel: '',
    email: '',
    siteweb: '',
  }

  public isLoading = false

  @Mutation
  public setInfoLegales (infoLegales: InfoLegales): void {
    this.infoLegales = infoLegales
  }

  @Mutation
  public setContact (contact: Contact): void {
    this.contact = contact
  }

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async loadCompanyFromSiret (siret: string): Promise<void> {
    this.context.commit('setIsLoading', true)

    const infoLegalesResponse = await http.get(`/api/companies/${siret}/infos_legales`)
    this.context.commit('setInfoLegales', infoLegalesResponse.data.infolegales)

    const contactResponse = await http.get(`/api/companies/${siret}/contact`)
    this.context.commit('setContact', contactResponse.data.data.contact)

    this.context.commit('setIsLoading', false)
  }
}

export default ApiCompanyModule
