/* eslint-disable @typescript-eslint/no-explicit-any */

/*
* In order to acces a module from another and get Typescript support,
* we followed https://github.com/championswimmer/vuex-module-decorators/issues/83  and the example here :
* https://github.com/garyo/vuex-module-decorators-example
*/

import Vue from 'vue'
import Vuex, { Store } from 'vuex'
// Note: you shouldn't need to import store modules here, import them in store/store-accessor instead
import { initializeStores, modules } from '@/store/store-accessor'

Vue.use(Vuex)

// Initialize the modules using a Vuex plugin that runs when the root store is
// first initialized. This is vital to using static modules because the
// modules don't know the root store when they are loaded. Initializing them
// when the root store is created allows them to be hooked up properly.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const initializer = (store: Store<any>) => initializeStores(store)
export const plugins = [initializer]
export * from '@/store/store-accessor' // re-export the modules

// Export the root store. You can add mutations & actions here as well.
// Note that this is a standard Vuex store, not a vuex-module-decorator one.
// (Perhaps could be, but I put everything in modules)
export default new Store({
  plugins, // important!
  modules, // important!
  state: { root: 'I am root' },
  mutations: {},
  actions: {},
})
