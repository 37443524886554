import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { RawLocation } from 'vue-router'
import { User } from '@/api/interfaces/user'
import http from '@/plugins/axios'
import { Trainer } from '@/api/interfaces/trainer'
import { Area } from '@/api/interfaces/area'
import AreaModule from '@/store/modules/area'
import { TrainingType } from '@/api/interfaces/trainingtype'
import TrainingTypeModule from '@/store/modules/trainingType'
import { UserGroup } from '@/api/interfaces/userGroup'

export interface Message {
  level: string;
  message: string;
}

export interface UserState {
  userConnected: User|Trainer|null
  message: Message|null
  routeAfterLogin: RawLocation
}

export interface LoginForm {
  email: string
  password: string
}

@Module({ name: 'user', namespaced: true })
class UserModule extends VuexModule implements UserState {
  public userConnected: User|Trainer|null = null;
  public message: Message|null = null;
  public routeAfterLogin= { path: '/' } as RawLocation;

  @Mutation
  public setUserConnected (user: User|Trainer|null): void {
    this.userConnected = user
  }

  @Mutation
  public setMessage (message: Message|null): void {
    this.message = message
  }

  @Mutation
  public setRouteAfterLogin (routeAfterLogin: RawLocation): void {
    this.routeAfterLogin = routeAfterLogin
  }

  get isTrainer (): boolean {
    return this.userConnected?.roles.indexOf('ROLE_TRAINER') !== -1
  }

  get isTrainerRegionManager (): boolean {
    return this.userConnected?.roles.indexOf('ROLE_TRAINER_REGION_MANAGER') !== -1
  }

  get isUser (): boolean {
    return this.userConnected?.roles.indexOf('ROLE_USER') !== -1
  }

  get roles ():string | Array<string> | undefined {
    return this.userConnected?.roles
  }

  get groups (): Array<string> | undefined {
    return this.userConnected?.userGroups?.map((group: UserGroup) => group.name)
  }

  get areasAuthorized (): Array<Area>|Array<string> {
    if (this.userConnected && (this.userConnected as Trainer)?.areas) {
      return (this.userConnected as Trainer)?.areas || AreaModule.state.list
    }

    return AreaModule.state.list
  }

  get trainingTypesAuthorized (): Array<TrainingType|Array<string>> {
    return TrainingTypeModule.state.list
  }

  get getLogisticAreaInFilters (): string|null {
    return this.userConnected?.userApplication?.filtersPlanning?.filters?.logisticArea || null
  }

  @Action({ rawError: true })
  public async login (loginForm: LoginForm): Promise<void> {
    this.context.commit('setMessage', null)
      try {
        const response = await http.post('/login', loginForm)
        const userResponse = await http.get(response.headers.location)
        this.context.commit('setUserConnected', userResponse.data)
        if (this.isTrainer) {
          this.context.commit('setRouteAfterLogin', { name: 'TrainersSpace' } as RawLocation)
        } else {
          this.context.commit('setRouteAfterLogin', { name: 'Planning' } as RawLocation)
        }
      } catch (err: any) {
        if (err.response.status === 401) {
          this.context.commit('setMessage', { level: 'warning', message: 'login.invalid-credentials' })
        } else {
          this.context.commit('setMessage', { level: 'error', message: err.response.data.error })
        }
        throw err
      }
  }

  @Action
  public logout (): void {
    this.context.commit('setUserConnected', null)
  }

  @Action
  public sendSuccessMessage (message: string): void {
      this.context.commit('setMessage', { level: 'success', message })
  }

  @Action
  public sendErrorMessage (message: string): void {
      this.context.commit('setMessage', { level: 'error', message })
  }

  @Action
  public sendWarningMessage (message: string): void {
      this.context.commit('setMessage', { level: 'warning', message })
  }

  @Action
  public deleteMessage (): void {
      this.context.commit('setMessage', null)
  }
}

export default UserModule
