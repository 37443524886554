import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
} from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)

extend('sharepointUrl', {
  validate: value => {
    if (value) {
      try {
        const url = new URL(value)
        const id = url.searchParams.get('id')
        return id !== null
      } catch (e) {
        return false
      }
    }

    return true
  },
  message: 'L\'URL sharepoint n\'est pas valide. Celle-ci ne contient pas le paramètre ID dans l\'URL.',
})

setInteractionMode('lazy')

localize('fr', fr)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
